type ColorGroup = {
  background: string
  text: string
}
type ColorsGroups =
  | 'primaryLight'
  | 'primaryDark'
  | 'onPrimaryLight'
  | 'neutralLight'
  | 'secondaryDark'
  | 'secondaryLight'
  | 'logoOrAccent'
  | 'onNeutralAndSecondaryLight'
  | 'onSecondaryDark'
  | 'neutralDark'
  | 'background'
  | 'textMediumLight'
  | 'neutralGrey'
  | 'error'
type ColorPalette = {
  [key in ColorsGroups]: ColorGroup
}

const neutralLight: ColorGroup = {
  background: '#FFF0E5',
  text: '#640726',
}

const primaryDark: ColorGroup = {
  background: '#C80043',
  text: neutralLight.background,
}

const onNeutralAndSecondaryLight: ColorGroup = {
  background: '#640726',
  text: neutralLight.background,
}

const neutralDark: ColorGroup = {
  background: '#322F2C',
  text: neutralLight.background,
}

const palette: ColorPalette = {
  neutralLight,
  primaryDark,
  primaryLight: {
    background: '#FBBEC6',
    text: primaryDark.background,
  },
  onPrimaryLight: {
    background: '#9F0237',
    text: neutralLight.background,
  },
  secondaryDark: {
    background: '#E86000',
    text: '#FFFFFF',
  },
  onNeutralAndSecondaryLight,
  secondaryLight: {
    background: '#FFC281',
    text: onNeutralAndSecondaryLight.background,
  },
  logoOrAccent: {
    background: '#FF9211',
    text: onNeutralAndSecondaryLight.background,
  },
  neutralDark,
  onSecondaryDark: {
    background: '#FFFFFF',
    text: neutralDark.background,
  },
  background: {
    background: '#FFFDFB',
    text: neutralDark.background,
  },
  textMediumLight: {
    background: '#615E5B',
    text: neutralLight.background,
  },
  neutralGrey: {
    background: '#D4D5D9',
    text: neutralDark.background,
  },
  error: {
    background: '#D11313',
    text: '#FFFFFF',
  },
}

export default palette
