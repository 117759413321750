import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../../../theme/colors.json'
import Typography from '../../../components/Typography'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import Touchable from '../../../components/Touchable'
import { useTranslation } from 'react-i18next'
import { properties } from '../../../theme'
import ExternalLink from './ExternalLink'
import Drawer from '../../../components/Drawer'

const useStyles = createUseStyles({
  visible: {
    visibility: 'visible',
    opacity: 1,
    transform: 'translateX(0%)',
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transform: 'translateX(-100%)',
  },
  row: {
    borderBottom: '1px solid ' + colors.strokePrimary,
    padding: '20px',
    margin: '0 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backButton: {
    backgroundColor: colors.backgroundSecondary,
    padding: 20,
    alignItems: 'center',
    display: 'flex',
  },
})

type Props = {
  open: boolean
  onNavigate: (path: string) => void
  onClose: () => void
}

type RowInfo = {
  label: string
  path: string
  categories?: string[] | null
}

export default function NavMenu({ open, onNavigate, onClose }: Props) {
  const styles = useStyles()
  const [selectedRow, setSelectedRow] = useState<RowInfo | undefined>()
  const { t } = useTranslation()
  function NavMenuView() {
    return (
      <div>
        {properties.navLinks
          .filter(({ navRender }) => navRender)
          .map(({ label, path, categories, navMenuDisplaySubcategories }) => (
            <Row
              key={label}
              label={t(label)}
              onClick={() => {
                if (navMenuDisplaySubcategories) {
                  setSelectedRow({
                    label: t(label),
                    path: `/${path}`,
                    categories,
                  })
                } else {
                  onNavigate(`/${path}`)
                }
              }}
            />
          ))}
        <ExternalLink
          onClick={() => {
            window.open(properties.storeLink.uri)
          }}
          className={styles.row}
          style={{
            justifyContent: 'flex-start',
          }}
          TypographyProps={{
            variant: 'nav-button1',
          }}
        >
          {properties.storeLink.title}
        </ExternalLink>
      </div>
    )
  }
  function RowMenu() {
    const { t } = useTranslation()
    const styles = useStyles()
    return (
      <div>
        <Touchable
          onClick={() => setSelectedRow(undefined)}
          className={styles.backButton}
        >
          <ArrowBackIos
            sx={{
              fontSize: '1.2rem',
              marginRight: '4px',
              top: '-2px',
              position: 'relative',
            }}
          />
          <Typography variant="nav-button1">{t('nav.menu.back')}</Typography>
        </Touchable>
        <Touchable
          onClick={() => onNavigate(selectedRow?.path ?? '')}
          className={styles.row}
        >
          <Typography variant="general-h3">
            {t('nav.menu.viewAll', { category: selectedRow?.label })}
          </Typography>
        </Touchable>
        {selectedRow?.categories?.map((slug) => (
          <Touchable
            className={styles.row}
            onClick={() => {
              onNavigate(selectedRow?.path + '?category_1=' + slug)
            }}
            key={slug}
          >
            <Typography variant="nav-button1">
              {t('item.categories.' + slug)}
            </Typography>
          </Touchable>
        ))}
      </div>
    )
  }
  return (
    <Drawer belowNav open={open} anchor="left" onClose={onClose}>
      {selectedRow == undefined ? <NavMenuView /> : <RowMenu />}
    </Drawer>
  )
}

function Row({ onClick, label }: { onClick: () => void; label: string }) {
  const styles = useStyles()
  return (
    <Touchable className={styles.row} onClick={onClick}>
      <Typography variant="nav-button1">{label}</Typography>
      <ArrowForwardIos />
    </Touchable>
  )
}
