import React from 'react'
import styled from 'styled-components'
import { colors, typographies } from '../theme'

const StyledInput = styled.div`
  input {
    border: 0px;
    width: 100%;
    color: ${colors.textPrimary};
    &::placeholder {
      color: ${colors.textSecondary};
    }
    ${typographies['nav-search']}
  }
`

type Props = {
  containerStyle?: React.CSSProperties
  inputClassName?: string
  onSubmit?: () => void
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export default function Input({
  containerStyle,
  inputClassName,
  onSubmit,
  ...props
}: Props) {
  return (
    <StyledInput style={containerStyle} className="input-container">
      <input
        className={[inputClassName ?? ''].join(' ')}
        onKeyPress={(e) => {
          // TODO: Confirm that this is working on mobile (https://www.w3.org/TR/uievents-key/#keys-whitespace)
          if (e.key === 'Enter') {
            onSubmit?.()
          }
        }}
        {...props}
      />
    </StyledInput>
  )
}
