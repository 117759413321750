import React, { useContext, useEffect, useState } from 'react'
import {
  useStripe,
  useElements,
  PaymentElement as StripePaymentElementComponent,
} from '@stripe/react-stripe-js'
import { useSearchParams } from '../../utils/useSearchParams'
import Typography from '../../components/Typography'
import { createUseStyles } from 'react-jss'
import recrqlColors from '../../theme/recrql/colors'
import { Stripe, StripeElements, StripePaymentElement } from '@stripe/stripe-js'
import { t } from 'i18next'
import Button from '../../components/Button'
import { handleError, handleSetupIntentResult } from './utils'
import { createPaymentMethod } from '../../api/payments/endpoints'
import { CreatePaymentMethodBody } from '../../api/payments/types'
import { handleAPIError } from '../../api/common/utils'
import { properties } from '../../theme'
import AuthContext from '../../contexts/AuthContext'

const useStyles = createUseStyles({
  addPaymentMethod: {},
  error: {
    marginTop: '24px !important',
    display: 'block',
    color: recrqlColors.error.background,
  },
})

type Props = {
  id: string
  onReady: (e: StripePaymentElement) => void
  onCreate: () => void
  onChange: (isComplete: boolean) => void
  onElements: (e: StripeElements | null) => void
}

export default function PaymentElement({
  id,
  onReady,
  onCreate,
  onChange,
  onElements,
}: Props) {
  const [{ auth }] = useContext(AuthContext)
  const [
    { setup_intent_client_secret, payment_intent_client_secret, as_default },
    setSearchParams,
  ] = useSearchParams()
  const stripe = useStripe()
  const elements = useElements()
  const styles = useStyles()

  const [isComplete, setIsComplete] = useState(false)
  const [paymentElement, setPaymentElement] =
    useState<StripePaymentElement | null>()
  const [error, setError] = useState<string | undefined>()

  useEffect(() => onElements(elements), [elements])

  useEffect(() => {
    if (stripe && setup_intent_client_secret) {
      handleSetupIntentClientSecret(stripe, setup_intent_client_secret)
    }
  }, [stripe])
  async function handleSetupNewPaymentMethod() {
    if (!stripe || !elements) {
      console.log('no stripe or elements')
      return
    }
    setIsLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_HOST}${properties.routerBaseName ?? ''}/checkout/${id}/?stripe_confirm=setup&as_default=true`,
      },
    })
    setIsLoading(false)
    if (error) {
      setError(error.message)
    }
  }
  const [isLoading, setIsLoading] = useState(false)
  async function handleAddNewPaymentMethod(body: CreatePaymentMethodBody) {
    console.log('add new payment method', body)
    const [_, error] = await createPaymentMethod(body)
    if (error) {
      setSearchParams({ error_message: handleAPIError(error) })
    } else {
      setSearchParams({})
      onCreate()
    }
  }
  async function handleSetupIntentClientSecret(
    stripe: Stripe,
    clientSecret: string
  ) {
    try {
      console.log('handling client secret')
      const setupIntentResult = await stripe.retrieveSetupIntent(clientSecret)
      const setupIntent = await handleSetupIntentResult(setupIntentResult)
      if (!setupIntent || !setupIntent.payment_method)
        throw t('checkout.unknownError')
      handleAddNewPaymentMethod({
        payment_method_id: setupIntent.payment_method,
        set_default: as_default ?? false,
      })
    } catch (error) {
      setError(handleError(error))
    }
  }
  return (
    <div className={styles.addPaymentMethod}>
      {!setup_intent_client_secret && !payment_intent_client_secret && (
        <>
          <StripePaymentElementComponent
            onReady={(e) => {
              setPaymentElement(e)
              onReady(e)
            }}
            onChange={({ complete }) => {
              setError(undefined)
              setIsComplete(complete)
              onChange(complete)
            }}
          />
          {!!paymentElement && !!auth && (
            <Button
              buttonStyle={{ marginTop: '16px' }}
              size="stretch"
              context="recrql"
              onClick={handleSetupNewPaymentMethod}
              text={t('button.add')}
              type="primary"
              state={isComplete && !isLoading ? 'active' : 'disabled'}
              isLoading={isLoading}
            />
          )}
        </>
      )}
      {error && (
        <Typography variant="recrql-label-medium" className={styles.error}>
          {error}
        </Typography>
      )}
    </div>
  )
}
