import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'
import Typography from '../../components/Typography'
import { t } from 'i18next'
import Button from '../../components/Button'
import { useIsMobile } from '../../utils/useWindowDimensions'
import PriceInfo from './PriceInfo'
import OrderInfo from './OrderInfo'
import {
  getAccountlessPurchase,
  getPurchase,
} from '../../api/purchases/endpoints'
import sleep from '../../utils/sleep'
import { useQuery } from '../../api/common/hooks'
import { colors, properties } from '../../theme'
import { ReactComponent as ReCRQLWithArrowsSVG } from '../../assets/recrql_with_arrows.svg'
import Touchable from '../../components/Touchable'
import styled, { css } from 'styled-components'
import SvgImg from '../../components/SvgImg'
import AddressInfo from './AddressInfo'
import * as localStorage from '../../utils/localStorage'
import { Purchase } from '../../api/purchases/types'
import AuthContext from '../../contexts/AuthContext'
import { authenticate } from '../../utils/auth'
import { QueryClient, useQueryClient } from 'react-query'

type StyleProps = {
  isMobile: boolean
}
const useStyles = createUseStyles<string, StyleProps>({
  checkoutConfirm: {
    padding: ({ isMobile }) => (isMobile ? '38px 17px' : '70px 70px 25px 70px'),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
    width: ({ isMobile }) => (isMobile ? 'unset' : '80%'),
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    marginTop: '20px',
  },
  trackParcelButtonContainer: {
    justifyContent: 'center',
    display: 'flex',
    margin: ({ isMobile }) => (isMobile ? '32px 0 0 0' : '0 0 0 25px'),
    flex: 1,
  },
  boxContainer: {
    marginTop: ({ isMobile }) => (isMobile ? '36px' : '59px'),
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    width: '100%',
  },
  box: {
    width: ({ isMobile }) => (isMobile ? 'unset' : 'calc(50% - 25px)'),
  },
  leftBox: {
    margin: ({ isMobile }) => (isMobile ? '25px 0 0 0' : '0 25px 0 0'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightBox: {
    margin: ({ isMobile }) => (isMobile ? '18px 0 0 0' : '0 0 0 25px'),
  },
  priceInfo: {
    height: 'calc(100% - 2px)',
    width: '100%',
  },
  orderInfoContainer: {
    marginBottom: ({ isMobile }) => (isMobile ? '17px' : 'unset'),
    width: ({ isMobile }) => (isMobile ? 'calc(100% - 45px)' : '100%'),
  },
})

const WhatHappensNowDiv = styled.div.attrs(
  (props: { isMobile: boolean }) => props
)`
  .title {
    margin: 130px 0 35px 0;
    display: block;
    text-align: center;
  }
  .boxes {
    display: flex;
    ${({ isMobile }) => css`
      flex-direction: ${isMobile ? 'column' : 'row'};
    `}
    justify-content: space-between;
  }
  .box {
    display: flex;
    ${({ isMobile }) =>
      isMobile
        ? css`
            flex-direction: row;
            margin: 0 0 16px 0;
            min-height: calc(102px - 64px);
          `
        : css`
            flex-direction: column;
            &:not(:first-child):not(:last-child) {
              margin: 0 16px 0 16px;
            }
            min-height: calc(206px - 64px);
          `}
    padding: 32px;
    background-color: ${colors.backgroundSecondary};
    flex: 1;
  }
  .box-title {
    ${({ isMobile }) =>
      isMobile
        ? css`
            margin: 0 16px 0 0;
          `
        : css`
            margin: 0 0 16px 0;
          `}
  }
`

type InfoProps = {
  className?: string
  purchase: Purchase
}

const ItemAddressInfo = ({ purchase, ...props }: InfoProps) => {
  const s = purchase.listing_data?.delivery_method == 'in_store'
  const ra = purchase.listing_data?.retailer_address
  if (s && !ra) return null
  const title = t(`address.${s ? 'storeAddress' : 'deliveryAddress'}`)
  const name = s
    ? purchase.listing_data?.seller_first_name
    : `${purchase.delivery_first_name} ${purchase.delivery_last_name}`
  const street = s ? ra!.street : purchase.delivery_street
  const postalCode = s ? ra!.postal_code : purchase.delivery_postal_code
  const city = s ? ra!.city : purchase.delivery_city
  return (
    <AddressInfo
      title={title}
      name={name}
      street={street}
      postalCode={postalCode}
      city={city}
      {...props}
    />
  )
}

function usePurchase(id: string, isAuthenticated?: boolean) {
  const [purchase, setPurchase] = useState<Purchase>()
  useEffect(() => {
    if (isAuthenticated !== undefined) {
      refetchPurchase()
    }
  }, [isAuthenticated])
  function refetchPurchase() {
    if (isAuthenticated) {
      getPurchase(id).then(setPurchase)
    } else {
      getAccountlessPurchase(id).then(setPurchase)
    }
  }
  return { purchase, refetchPurchase }
}

export default function CheckoutConfirm() {
  const { id } = useParams<{ id: string }>()
  if (!id) return null
  const [{ isAuthenticated }] = useContext(AuthContext)
  const { purchase, refetchPurchase } = usePurchase(id, isAuthenticated)

  const isMobile = useIsMobile()
  const styles = useStyles({ isMobile })
  const purchasedItem = useMemo(() => {
    const purchasedItem = localStorage.getItem('purchasedItem')
    if (purchasedItem?.id !== purchase?.item) {
      return undefined
    } else {
      return purchasedItem
    }
  }, [purchase])

  useEffect(() => {
    if (purchase && !purchase.delivery_tracking_url) {
      ;(async function () {
        await sleep(5000)
        refetchPurchase()
      })()
    }
  }, [purchase])
  return (
    <div className={styles.checkoutConfirm}>
      <Typography className={styles.title} variant="recrql-title-medium">
        {t('checkout.success.title')}
      </Typography>
      <Typography className={styles.subtitle} variant="recrql-label-medium">
        {t('checkout.success.subtitle')}
      </Typography>
      <div className={styles.boxContainer}>
        <div className={[styles.box, styles.leftBox].join(' ')}>
          {purchase && (
            <>
              <div className={styles.orderInfoContainer}>
                <OrderInfo purchase={purchase} />
              </div>
              <div style={{ width: '100%' }}>
                <ItemAddressInfo purchase={purchase} />
              </div>
            </>
          )}
        </div>
        {purchasedItem && purchase && (
          <div className={[styles.box, styles.rightBox].join(' ')}>
            <PriceInfo
              context="checkout-confirm"
              className={styles.priceInfo}
              item={purchasedItem}
              purchase={purchase}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          marginTop: '25px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            marginRight: isMobile ? 'unset' : '25px',
          }}
        >
          <Typography variant="recrql-label-thin">
            Hjelmberg Sustainable Solutions AB 559295-5073
          </Typography>
        </div>
        {purchase?.listing_data?.delivery_method == 'postnord' && (
          <div className={styles.trackParcelButtonContainer}>
            <Button
              size="small"
              state={purchase?.delivery_tracking_url ? 'active' : 'disabled'}
              type="secondary"
              context="recrql"
              buttonStyle={{ width: '100%' }}
              text={t('checkout.success.trackParcel')}
              onClick={() => {
                window.open(purchase?.delivery_tracking_url)
              }}
            />
          </div>
        )}
      </div>
      {properties.layoutSettings.checkoutConfirm.displayWhatHappensNow && (
        <WhatHappensNowDiv isMobile={isMobile}>
          <Typography variant="general-h2" className="title">
            {t('checkout.success.whatHappensNow.title')}
          </Typography>
          <div className="boxes">
            <div className="box">
              <Typography variant="general-h2" className="box-title">
                {t('checkout.success.whatHappensNow.first.title')}
              </Typography>
              <Typography variant="general-body1" className="box-body">
                {t('checkout.success.whatHappensNow.first.body')}
              </Typography>
            </div>
            <div className="box">
              <Typography variant="general-h2" className="box-title">
                {t('checkout.success.whatHappensNow.second.title')}
              </Typography>
              <Typography variant="general-body1" className="box-body">
                {t('checkout.success.whatHappensNow.second.body')}
              </Typography>
            </div>
            <div className="box">
              <Typography variant="general-h2" className="box-title">
                {t('checkout.success.whatHappensNow.third.title')}
              </Typography>
              <Typography variant="general-body1" className="box-body">
                {t('checkout.success.whatHappensNow.third.body')}
              </Typography>
            </div>
          </div>
        </WhatHappensNowDiv>
      )}
      <AppLinksContainer isMobile={isMobile}>
        <Typography className="app-title" variant="recrql-label-medium">
          {t('checkout.success.whatHappensNow.appDescription')}
        </Typography>
        <div className="app-links-container">
          <ReCRQLWithArrowsSVG className="recrql-with-arrows" />
          <div className="app-links">
            <Touchable
              onClick={() => window.open(properties.appLinks.ios)}
              style={{ margin: '0 16px' }}
            >
              <SvgImg src={'assets/icon_appstore_se.svg'} />
            </Touchable>
            <Touchable onClick={() => window.open(properties.appLinks.android)}>
              <SvgImg src={'assets/icon_googleplay_se.svg'} />
            </Touchable>
          </div>
        </div>
      </AppLinksContainer>
    </div>
  )
}

const AppLinksContainer = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => `
  .app-links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${
      isMobile
        ? `
      margin: 0 0 37.39px 0;
      flex-direction: column;
    `
        : `
      margin: 0 0 103.2px 0;
      flex-direction: row;
    `
    }
  }
  .app-title {
    text-align: center;
    margin: 50px 0 30px 0;
    display: block;
  }
  .recrql-with-arrows {
    ${
      isMobile
        ? `
      width: 48px;
      height: 48px;
    `
        : `
      width: 60px;
      height: 60px;
    `
    }
  }
  .app-links {
    display: flex;
    margin-top: ${isMobile ? '24px' : '0px'};
  }
`
)
