import {
  URLSearchParamsInit,
  useSearchParams as useSearchParamsRR,
} from 'react-router-dom'

function getUrlSearchParamsObject(urlSearchParams: URLSearchParams) {
  const params: { [key: string]: string } = {}
  for (const [key, value] of urlSearchParams.entries()) {
    params[key] = value
  }
  return params
}

export function useSearchParams<T extends { [key: string]: any }>(): readonly [
  T,
  (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined
          state?: any
        }
      | undefined
  ) => void,
] {
  const [searchParams, setSearchParams] = useSearchParamsRR()
  return [getUrlSearchParamsObject(searchParams) as T, setSearchParams]
}
