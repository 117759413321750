import { t } from 'i18next'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Typography from '../../components/Typography'
import CopyContainer from '../CopyContainer'

const useStyles = createUseStyles({
  aboutReCRQL: {},
})

export default function AboutReCRQLView() {
  const styles = useStyles()
  return (
    <CopyContainer title={t('footer.aboutRecrql')} subtitles={[]}>
      <Typography variant="general-body1">
        <div
          dangerouslySetInnerHTML={{
            __html: t('aboutRecrql', { interpolation: { escapeValue: false } }),
          }}
        />
      </Typography>
    </CopyContainer>
  )
}
