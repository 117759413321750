import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useIsPathActive = (path: string) => {
  const location = useLocation()
  return useMemo(
    () => location.pathname.includes(path),
    [location.pathname, path]
  )
}
