import { APIError, Pagination, PaginationParams } from '../common/types'
import { CreatePaymentMethodBody, PaymentMethod, SetupIntent } from './types'
import fetcher, { fetchErrHandler } from '../fetcher'
import { Item } from '../items/types'
import { PaymentIntent } from '@stripe/stripe-js'

export function getPaymentMethods(
  params: PaginationParams
): Promise<Pagination<PaymentMethod>> {
  return fetcher({
    path: '/payments/payment-method/',
    options: { method: 'GET' },
    authenticate: true,
    params,
  })
}
type CreatePaymentMethodError = APIError<CreatePaymentMethodBody>

export function createPaymentMethod(body: CreatePaymentMethodBody) {
  return fetchErrHandler<PaymentMethod, CreatePaymentMethodError>(() =>
    fetcher<PaymentMethod>({
      path: '/payments/payment-method/',
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
      authenticate: true,
    })
  )
}

export function updatePaymentMethod(body: CreatePaymentMethodBody) {
  return fetchErrHandler<PaymentMethod, CreatePaymentMethodError>(() =>
    fetcher<PaymentMethod>({
      path: `/payments/payment-method/${body.payment_method_id}/`,
      options: {
        method: 'PATCH',
        body: JSON.stringify(body),
      },
      authenticate: true,
    })
  )
}

export function deletePaymentMethod(id: PaymentMethod['id']) {
  return fetchErrHandler<void, APIError<object>>(() =>
    fetcher({
      path: `/payments/payment-method/${id}/`,
      options: { method: 'DELETE' },
      authenticate: true,
    })
  )
}

export function getSetupIntent() {
  return fetchErrHandler<SetupIntent, object>(() =>
    fetcher({
      path: '/payments/setup-intent/',
      options: {
        method: 'POST',
        body: JSON.stringify({}),
      },
      authenticate: true,
    })
  )
}

export function getPaymentIntent(item_id: Item['id']) {
  return fetchErrHandler<PaymentIntent, object>(() =>
    fetcher({
      path: `/payments/${item_id}/payment-intent/`,
      options: {
        method: 'POST',
        body: JSON.stringify({}),
      },
      authenticate: false,
    })
  )
}
