import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Icon from '../../../components/Icon'
import Typography from '../../../components/Typography'
import { colors, properties } from '../../../theme'
import { LabelIcon } from '../../../theme/types'
import { useIsMobile } from '../../../utils/useWindowDimensions'
import zIndexes from '../../../utils/zIndexes'

export default function Usps() {
  const usps = properties.usps
  const isMobile = useIsMobile()
  if (!usps || usps.length == 0) return null
  const mobileUsps = usps
    .filter((u) => u.mobileOrder != null)
    .sort((u) => u.mobileOrder!)
  return (
    <UspsContainer id="usps" className="usps">
      {(isMobile ? mobileUsps : usps).map((usp) => (
        <UspThing key={usp.label} {...usp} />
      ))}
    </UspsContainer>
  )
}

const UspsContainer = styled.div`
  height: 44px;
  width: 100%;
  background-color: ${colors.backgroundPrimary};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndexes.usps};
  .label-icon {
    display: flex;
    align-items: center;
    margin: 0 8px;
    .MuiIcon-root {
      color: ${colors.primaryAccent};
      margin-right: 2px;
    }
    .typography {
      white-space: nowrap;
      margin-left: 2px;
    }
  }
`

function UspThing({ label, icon }: LabelIcon) {
  const { t } = useTranslation()
  return (
    <div className="label-icon">
      {!!icon && <Icon name={icon} />}
      <Typography variant="nav-body1">{t(label)}</Typography>
    </div>
  )
}
