import React, { useState } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Typography from '../../components/Typography'
import Breadcrumb from '../common/navigation/Breadcrumb'
import ItemsTable from '../items/ItemsTable'

const StyledSearchResult = styled.div`
  padding: 27px 80px;
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 68px;
  }
  .search-result-title-line {
    width: 135px;
    height: 1px;
    background-color: #000;
    margin-top: 17px;
  }
`

export default function SearchResult() {
  const { query } = useParams()
  const [count, setCount] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  return (
    <StyledSearchResult className="search-result">
      <Breadcrumb title={t('nav.search')} />
      <div className="text-container">
        {isLoading ? (
          <Typography variant="general-h2">{t('loading')}</Typography>
        ) : (
          <Typography variant="general-h2">
            <Trans
              i18nKey="searchResult"
              components={[
                <span style={{ color: 'rgba(0,0,0,0.3)' }} key={''} />,
              ]}
              values={{ count, query }}
            />
          </Typography>
        )}
        <div className="search-result-title-line" />
      </div>
      <ItemsTable
        searchParams={{ search: query }}
        onQuery={({ count, isLoading }) => {
          setCount(count)
          setIsLoading(isLoading)
        }}
      />
    </StyledSearchResult>
  )
}
