import React from 'react'
import { ShoppingBagOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button'
import useItem from './useItem'
import { layoutSettings } from './utils'

export default function PurchaseButton() {
  const { item } = useItem()
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Button
      startIcon={
        layoutSettings.purchaseButton.displayButtonIcon ? (
          <ShoppingBagOutlined />
        ) : undefined
      }
      state={item ? 'active' : 'disabled'}
      type="primary"
      size={layoutSettings.purchaseButton.size}
      text={t('checkout.purchase')}
      buttonStyle={{ margin: '26px 0 38px 0' }}
      onClick={() => {
        navigate(`/checkout/${item?.id}/`)
      }}
    />
  )
}
