import React, { useEffect } from 'react'
import { ThemeProvider } from '@mui/system'
import { Outlet, useParams, useNavigate } from 'react-router-dom'
import recrqlTheme from '../../theme/recrql/theme'

export default function CheckoutRouteProvider() {
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (!id) navigate('/')
  }, [])
  return (
    <ThemeProvider theme={recrqlTheme}>
      <Outlet />
    </ThemeProvider>
  )
}
