import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Item } from '../../api/items/types'
import { getItemSubPath } from '../../theme/utils'
import Touchable from '../../components/Touchable'
import Typography, { TypographyProps } from '../../components/Typography'
import { useIsMobile } from '../../utils/useWindowDimensions'
import { colors, properties, styles } from '../../theme'
import styled from 'styled-components'
import Button from '../../components/Button'
import SvgImg from '../../components/SvgImg'
import zIndexes from '../../utils/zIndexes'
import { getItemImg } from '../../api/items/utils'
import { template } from './detail/utils'

const layoutSettings = properties.layoutSettings.itemRow
const defaultSoptions = Object.keys(properties.sustainabilityOptions).filter(
  (k) => properties.sustainabilityOptions[k].default
)

const ItemRowContainer = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => `
  width: 50%;
  .item-row-sub {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px 6px;
    position: relative;
  }
  @media screen and (min-width: 52em) {
    width: 33.33333333333333%;
    .item-row-sub {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media screen and (min-width: 64em) {
    width: 25%;
  }
  .img-container {
    position: relative;
    width: 100%;
    padding-bottom: 150%;
    overflow: hidden;
    margin-bottom: 24px;
    .img-placeholder {
      background-color: rgb(245, 244, 240);
      position: absolute;
      inset: 0;
      width: 100%;
      z-index: ${zIndexes.itemRowImgPlaceholder};
    }
    > img {
      transform: translate(-50%, -50%);
      height: 100%;
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: ${zIndexes.itemRowImg};
    }
  }
  .sustainability-options-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    z-index: ${zIndexes.itemRowSoptionsContainer};
    img {
      padding: ${isMobile ? '4px 0' : '6px 4px'};
    }
  }
`
)

export interface Props {
  item: Item
  index: number
}

export default function ItemRow({ item }: Props) {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const subpath = getItemSubPath(item)
  const soptions = [...(item.sustainability_options ?? []), ...defaultSoptions]
    .filter((key) => key in properties.sustainabilityOptions)
    .map((key) => {
      const { icon } = properties.sustainabilityOptions[key]
      return (
        <SvgImg
          key={'soptionimg' + key}
          src={`assets/icons/${icon}`}
          width={isMobile ? 11 : 20}
        />
      )
    })
  function handlePurchase() {
    navigate(`/checkout/${item.id}/`)
  }
  const imageUrl = getItemImg(item, properties.partnerImageArgs.itemRow)
  const discounted =
    layoutSettings.displayOriginalPrice && !!item.original_list_price
  return (
    <ItemRowContainer className="item-row" isMobile={isMobile}>
      <div className="item-row-sub">
        <Touchable
          onClick={() => navigate(`/${subpath}/${item.id}`)}
          className="img-container"
        >
          <div className="img-placeholder" />
          <img src={imageUrl} />
          {soptions.length && (
            <div className="sustainability-options-container">{soptions}</div>
          )}
          {template == 'outnorth' && item.listing.condition == 'new' && (
            <Typography variant="listing-condition">
              {t(`item.condition.${item.listing.condition}`)}!
            </Typography>
          )}
        </Touchable>
        <div className="item-row-info-container">
          <div className="item-row-title-container">
            {properties.partner.slug == 'stjarnurmakarna' &&
              !!item.raw_data?.brand && (
                <Typography variant="listing-subtitle">
                  {item.raw_data.brand}
                </Typography>
              )}
            <Typography variant="listing-title">{item.title}</Typography>
            {template == 'outnorth' && !!item?.brand && (
              <Typography variant="listing-subtitle">{item.brand}</Typography>
            )}
          </div>
          <div className="price-container">
            {discounted && (
              <ItemRowPrice discounted={false} variant="listing-price2">
                {t('item.price', { val: item.original_list_price })}
              </ItemRowPrice>
            )}
            <ItemRowPrice discounted={discounted} variant="listing-price1">
              {t('item.price', { val: item.listing.price })}
            </ItemRowPrice>
          </div>
        </div>
        {layoutSettings.showPurchaseButton && (
          <Button
            context="partner"
            state="active"
            type="primary"
            size="small"
            onClick={handlePurchase}
            text={t('checkout.purchase')}
          />
        )}
      </div>
    </ItemRowContainer>
  )
}

type ItemRowPriceProps = {
  discounted: boolean
} & TypographyProps
const ItemRowPrice = styled(
  ({ discounted: _, ...props }: ItemRowPriceProps) => <Typography {...props} />
)(
  ({ discounted }) => `
  margin-top: 6px;
  ${discounted ? `color: ${colors.primaryAccent} !important;` : ''}
`
)
