import React from 'react'
import { Menu } from '@mui/icons-material'
import { createUseStyles } from 'react-jss'
import Touchable from '../../../components/Touchable'
import colors from '../../../theme/colors.json'
import NavLink from './NavLink'
import NavRight from './NavRight'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { PartnerLogo, ReCRQLLogo } from '../../../components/Logo'
import zIndexes from '../../../utils/zIndexes'
import { properties } from '../../../theme'
import { template } from '../../items/detail/utils'

const useStyles = createUseStyles({
  navMobileContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '18px 25px',
    zIndex: zIndexes.navMobileContainer,
    backgroundColor: colors.backgroundPrimary,
  },
  menuContainer: {
    flex: 1,
  },
  middleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  link: {
    padding: '0 25px',
  },
  superContainer: {},
})

type Props = {
  onClickSearch: () => void
  onClickNavMenu: () => void
}

export default function NavMobile({ onClickNavMenu, onClickSearch }: Props) {
  const styles = useStyles()
  const navigate = useNavigate()
  return (
    <>
      <div className={styles.navMobileContainer}>
        <div className={styles.menuContainer}>
          <Touchable onClick={onClickNavMenu}>
            <Menu />
          </Touchable>
        </div>
        <div className={styles.middleContainer}>
          {template == 'outnorth' ? (
            <ReCRQLLogo
              size="medium"
              className="touchable"
              onClick={() => navigate('/')}
            />
          ) : (
            <PartnerLogo
              size="small"
              className="touchable"
              onClick={() => navigate('/')}
            />
          )}
        </div>
        <NavRight onClickSearch={onClickSearch} />
      </div>
      <Sublogo className="mobile-sublogo">
        {template == 'outnorth' ? (
          <PartnerLogo
            onClick={() => window.open(properties.storeLink.uri)}
            size="small"
            className="touchable"
          />
        ) : (
          <ReCRQLLogo size="small" />
        )}
      </Sublogo>
    </>
  )
}

const Sublogo = styled.div`
  background-color: ${colors.backgroundSecondary};
  padding: 6px 0;
  justify-content: center;
  display: flex;
  z-index: ${zIndexes.navMobileSubLogo};
  position: relative;
`
