import React from 'react'
import Typography from './Typography'
import colors from '../theme/colors.json'
import styled from 'styled-components'
import { AutoExpander } from './Expander'

const StyledExpander = styled(AutoExpander)`
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  margin-bottom: 16px;
  .title {
    border: 1px solid ${colors.strokePrimary};
  }
  .contents {
    padding: 20px 20px 29px 20px !important;
  }
`

type Props = {
  title: string
  text: string | React.ReactNode
}

export default function FAQExpander({ title, text }: Props) {
  return (
    <StyledExpander
      titleVariant="general-subtitle1"
      title={title}
      contents={<Typography variant="general-body1">{text}</Typography>}
    />
  )
}
