import React from 'react'
import styled from 'styled-components'
import { DivProps } from '../utils/types'

const StyledTouchable = styled.div.attrs(({ className }) => ({
  className: ['touchable', className].join(' '),
}))<StyleProps>(
  ({ isDisabled }) => `
  cursor: ${isDisabled ? 'default' : 'pointer'};
  opacity: ${isDisabled ? '0.3' : '1.0'};
`
)

type StyleProps = Omit<Props, 'style' | 'className'>

type Props = {
  isDisabled?: boolean
} & DivProps

export default function Touchable({
  isDisabled,
  children,
  onClick,
  ...props
}: Props) {
  return (
    <StyledTouchable
      onClick={isDisabled ? undefined : onClick}
      isDisabled={isDisabled}
      {...props}
    >
      {children}
    </StyledTouchable>
  )
}
