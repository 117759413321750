import React from 'react'
import { AuthContextProvider } from './AuthContext'

export const RootContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <AuthContextProvider>{children}</AuthContextProvider>
}
