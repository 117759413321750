import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Touchable from './Touchable'
import Typography, { TypographyProps, TypographyVariant } from './Typography'

const StyledTextBody = styled.span`
  display: flex;
  flex-direction: column;
`

type Props = {
  text?: string
  truncatedText?: string
  charLimit?: number
  buttonVariant?: TypographyVariant
} & Omit<TypographyProps, 'children'>

export default function TextBody({
  text,
  truncatedText: _truncatedText,
  charLimit = 100,
  buttonVariant,
  ...props
}: Props) {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()
  const overCharLimit = (text ?? '').length > charLimit
  const truncateText = overCharLimit && !expanded
  const truncatedText = _truncatedText ?? text?.substring(0, charLimit) + '...'
  return (
    <StyledTextBody>
      <Typography {...props}>{truncateText ? truncatedText : text}</Typography>
      {!!charLimit && overCharLimit && (
        <Touchable onClick={() => setExpanded((p) => !p)}>
          <Typography variant={buttonVariant ?? 'general-link'}>
            {t(`button.${expanded ? 'readLess' : 'readMore'}`)}
          </Typography>
        </Touchable>
      )}
    </StyledTextBody>
  )
}
