import { Item } from '../api/items/types'
import { PurchaseRequest } from '../api/purchases/types'
import { AuthContextState } from '../contexts/AuthContext'

export const LOCAL_STORAGE_EVENT = 'recrql/localStorage.setItem'

export type LocalStorage = {
  pendingPurchase?: PurchaseRequest
  purchasedItem?: Item
} & AuthContextState

export function getItem<
  T extends LocalStorage[K],
  K extends keyof LocalStorage,
>(key: K): T | undefined {
  const item = localStorage.getItem(key)
  let value: T | undefined
  try {
    value = item ? (JSON.parse(item) as T) : undefined
  } catch {
    value = undefined
  }
  return value
}

export function setItem<
  T extends LocalStorage[K],
  K extends keyof LocalStorage,
>(key: K, value: T) {
  const j = JSON.stringify(value)
  localStorage.setItem(key, j)
  const eventType = getEventType(key)
  window.dispatchEvent(new Event(eventType))
}

export function getEventType<K extends keyof LocalStorage>(key: K): string {
  return `${LOCAL_STORAGE_EVENT}/${key}`
}
