import { properties } from '../../../theme'
import useWindowDimensions from '../../../utils/useWindowDimensions'

const layoutSettings = properties.layoutSettings.itemDetail
const defaultSustainabilityOptions = Object.keys(
  properties.sustainabilityOptions
).filter((k) => properties.sustainabilityOptions[k].default)
const partner = properties.partner.slug
const template = properties.template

export { partner, template, layoutSettings, defaultSustainabilityOptions }

export function useIsSmallScreen() {
  const { width } = useWindowDimensions()
  return width <= DETAIL_SMALL_SCREEN
}

export const DETAIL_SMALL_SCREEN = 1120
