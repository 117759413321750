import { OpenInNew } from '@mui/icons-material'
import React from 'react'
import styled from '@emotion/styled'
import Typography, { TypographyProps } from '../../../components/Typography'

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledTypography = styled(Typography)`
  margin-right: 10.25px;
  white-space: nowrap;
`

const StyledOpenInNew = styled(OpenInNew)`
  font-size: 1rem;
  top: -1.5px;
  position: relative;
`

export type Props = {
  TypographyProps: TypographyProps
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>

export default function ExternalLink({
  TypographyProps,
  children,
  ref: ___,
  className,
  ...props
}: Props) {
  return (
    <StyledDiv className={['external-link', className].join(' ')} {...props}>
      <StyledTypography {...TypographyProps}>{children}</StyledTypography>
      <StyledOpenInNew />
    </StyledDiv>
  )
}
