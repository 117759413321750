import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '../../components/Typography'
import { useIsMobile } from '../../utils/useWindowDimensions'
import ItemsFilterTable from '../items/ItemsFilterTable'
import styled from 'styled-components'
import zIndexes from '../../utils/zIndexes'
import Button from '../../components/Button'

const HomeContainer = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => `
  padding: ${isMobile ? '70px 20px' : '100px 80px'};
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: ${zIndexes.homeContainer};
  align-items: center;
  .texts-container {
    width: ${isMobile ? 'inherit' : '80%'};
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .description-container {
    margin-top: 26px;
    width: ${isMobile ? 'auto' : '600px'};
    margin-bottom: ${isMobile ? '48px' : '60px'};
  }
`
)

export default function Home() {
  const isMobile = useIsMobile()
  const { t, i18n } = useTranslation()
  const hasShortBody = i18n.exists('home.shortBody')
  const [bodyExpanded, setBodyExpanded] = useState(!hasShortBody)
  return (
    <HomeContainer className="home" isMobile={isMobile}>
      <div className="texts-container">
        <Title />
        <div className="description-container">
          <Typography variant="general-body2">
            <div
              dangerouslySetInnerHTML={{
                __html: t(`home.${bodyExpanded ? 'body' : 'shortBody'}`, {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Typography>
          {hasShortBody && (
            <Button
              context="partner"
              type="link"
              state="active"
              onClick={() => setBodyExpanded((p) => !p)}
              size="auto"
              text={t(`button.${bodyExpanded ? 'readLess' : 'readMore'}`)}
            />
          )}
        </div>
      </div>
      <ItemsFilterTable />
    </HomeContainer>
  )
}

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const TitleLine = styled.div`
  margin-top: 13px;
  background-color: #000;
  height: 1px;
  width: 135px;
`

function Title() {
  const { t } = useTranslation()
  return (
    <TitleContainer className="title-container">
      <Typography
        className="home-title"
        style={{ position: 'relative' }}
        variant="general-h1"
      >
        {t('home.header')}
      </Typography>
      <TitleLine className="home-title-line" />
    </TitleContainer>
  )
}
