import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Expander from '../../../components/Expander'
import Hyperlink from '../../../components/Hyperlink'
import Typography from '../../../components/Typography'
import { properties } from '../../../theme'
import Divider from './Divider'
import useItem from './useItem'
import { layoutSettings, template, useIsSmallScreen } from './utils'

interface ExpanderState {
  [key: string]: {
    isExpanded: boolean
    title: string
    contents: React.ReactNode
  }
}

const StyledExpanders = styled.div<{ isSmallScreen: boolean }>(
  ({ isSmallScreen }) => `
  ${
    isSmallScreen
      ? `
    width: 100%;
  `
      : ''
  }
`
)

export default function Expanders() {
  const { item, sustainabilityOptions } = useItem()
  const [expanders, setExpanders] = useState<ExpanderState | undefined>()
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()

  useEffect(() => {
    if (
      expanders == undefined &&
      item != undefined &&
      layoutSettings.displayExpanders
    ) {
      const _expanders: ExpanderState = {
        desc: {
          isExpanded: false,
          title: 'item.description',
          contents: (
            <Typography variant="detail-body1">
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
              {template == 'outnorth' && (
                <>
                  <ul>
                    {Object.keys(item.properties ?? {})
                      .filter((k) => k != 'Hållbarhet' && k != 'Material')
                      .map((k) => (
                        <DescProperty
                          key={k}
                          propertyKey={k}
                          value={item.properties![k]}
                        />
                      ))}
                  </ul>
                </>
              )}
              {item.product_url && (
                <Hyperlink
                  variant="detail-link"
                  text={t('item.goProductUrl')}
                  href={item.product_url}
                />
              )}
            </Typography>
          ),
        },
      }
      if (item.material) {
        _expanders.material = {
          isExpanded: false,
          title: 'item.material',
          contents: (
            <Typography variant="detail-body1">
              <div dangerouslySetInnerHTML={{ __html: item.material }} />
            </Typography>
          ),
        }
      }
      if (sustainabilityOptions.length || item.sustainability) {
        _expanders.sust = {
          isExpanded: false,
          title: 'item.sustainability',
          contents: item.sustainability ? (
            <Typography variant="detail-body1">
              {item.sustainability}
            </Typography>
          ) : (
            <div>
              {sustainabilityOptions
                .filter(({ description }) => t(description).length)
                .map(({ title, description }, index) => {
                  const isFirst = index == 0
                  return (
                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      key={'sopts' + title + index}
                    >
                      <Typography
                        style={{
                          margin: isFirst ? '0 0 6px 0' : '30px 0 6px 0',
                        }}
                        variant="detail-subtitle2"
                      >
                        {t(title)}
                      </Typography>
                      <Typography variant="detail-body1">
                        {t(description, { partner: properties.partner.name })}
                      </Typography>
                    </div>
                  )
                })}
            </div>
          ),
        }
      }
      setExpanders(_expanders)
    }
  }, [item])
  if (!expanders) return null
  return (
    <StyledExpanders isSmallScreen={isSmallScreen} className="expanders">
      <Divider withoutMargin />
      {Object.keys(expanders).map((key, index) => {
        const { isExpanded, title, contents } = expanders[key]
        return (
          <div style={{ width: '100%' }} key={'expander' + key + index}>
            <Expander
              titleVariant="detail-h1"
              isExpanded={isExpanded}
              title={t(title)}
              contents={contents}
              onToggle={() => {
                setExpanders((p) => {
                  if (!p) return p
                  return {
                    ...p,
                    [key]: { ...p[key], isExpanded: !p[key].isExpanded },
                  }
                })
              }}
            />
            <Divider withoutMargin />
          </div>
        )
      })}
    </StyledExpanders>
  )
}

function DescProperty({
  propertyKey,
  value,
}: {
  propertyKey: string
  value: string
}) {
  const fValue = value == 'false' ? 'Nej' : value == 'true' ? 'Ja' : value
  return (
    <li>
      {propertyKey}: {fValue}
    </li>
  )
}
