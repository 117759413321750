import { properties } from '../theme'

if (
  !!properties.routerBaseName &&
  !window.location.pathname.includes(properties.routerBaseName)
) {
  window.history.replaceState(
    '',
    '',
    properties.routerBaseName + window.location.pathname
  )
}
