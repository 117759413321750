import React from 'react'
import { getPartners } from '../../api/partners/queries'
import NavigationView from '../common/navigation/NavigationView'

export default function Partners() {
  const partners = getPartners({
    offset: 0,
    limit: 4,
  })
  return (
    <>
      <main>
        <h2>{partners.data.length} partners</h2>
        {partners.data.map((partner, index) => (
          <div key={index}>{partner.name}</div>
        ))}
        {partners.hasPreviousPage && (
          <button
            onClick={() => {
              partners.fetchPreviousPage()
            }}
          >
            Prev
          </button>
        )}
        {partners.hasNextPage && (
          <button
            onClick={() => {
              partners.fetchNextPage()
            }}
          >
            Next
          </button>
        )}
      </main>
      <NavigationView />
    </>
  )
}
