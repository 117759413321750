import { StripeElements } from '@stripe/stripe-js'
import { useNavigate } from 'react-router-dom'
import { Item } from './../../api/items/types'
import { useEffect } from 'react'
import { properties } from '../../theme'
import { useSearchParams } from '../../utils/useSearchParams'
import {
  handleAccountlessPurchase,
  handleAccountPurchase,
  handleError,
  handlePaymentIntentResult,
} from './utils'
import { useTranslation } from 'react-i18next'
import { getPaymentIntent } from '../../api/payments/endpoints'
import { PurchaseRequest } from '../../api/purchases/types'
import * as localStorage from '../../utils/localStorage'
import { Stripe } from '@stripe/stripe-js'
import { PaymentMethod } from '../../api/payments/types'

export default function usePaymentHandler(
  stripe: Stripe | null,
  elements: StripeElements | null,
  item: Item | undefined,
  pendingPurchase: PurchaseRequest,
  setIsLoading: (isLoading: boolean) => void,
  setError: (err: string) => void
) {
  const [{ payment_intent_client_secret }] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    // Make sure everything is initialised before concluding purchase
    if (payment_intent_client_secret && stripe && elements && item)
      accountlessPurchase()
  }, [payment_intent_client_secret, stripe, elements, item])

  async function accountPurchase(paymentMethods: PaymentMethod[]) {
    setIsLoading(true)
    setError('')
    const defaultPaymentMethod = paymentMethods.find((i) => i.is_default)
    if (!item || !defaultPaymentMethod || !stripe) {
      setError('Missing stripe, item or default payment method')
      return
    }
    try {
      const [purchase, error] = await handleAccountPurchase(stripe, item.id, {
        ...pendingPurchase,
        payment_method_id: defaultPaymentMethod.id,
        payment_intent_id: undefined,
      })
      if (error) throw error
      else if (purchase && item) {
        console.debug('Purchase success')
        localStorage.setItem('pendingPurchase', undefined)
        localStorage.setItem('purchasedItem', item)
        navigate(`/purchase/${purchase.id}/`)
      } else {
        throw 'Missing purchase object or item object'
      }
    } catch (error) {
      console.debug(error)
      setError(handleError(error))
    } finally {
      setIsLoading(false)
    }
  }

  async function accountlessPurchase() {
    if (!item) {
      setError('Missing item')
      return
    }
    setIsLoading(true)
    setError('')
    if (!stripe || !elements) {
      setError('Missing stripe or elements')
      return
    }
    try {
      if (!payment_intent_client_secret) {
        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_HOST}${properties.routerBaseName ?? ''}/checkout/${item.id}/?stripe_confirm=payment`,
          },
        })
        if (result.error) {
          console.debug('Stripe confirm payment error', result.error)
          throw result.error
        }
      } else {
        console.debug('Handling payment intent client secret')
        const paymentIntentResult = await stripe.retrievePaymentIntent(
          payment_intent_client_secret
        )
        const paymentIntent = await handlePaymentIntentResult(
          paymentIntentResult,
          { stripe }
        )
        if (!paymentIntent) throw t('checkout.unknownError')

        const [purchase, error] = await handleAccountlessPurchase(
          stripe,
          item.id,
          {
            purchase: pendingPurchase,
            email: pendingPurchase.email!,
            phone_number: pendingPurchase.phone_number,
            payment_intent_id: paymentIntent.id,
            payment_method_id: paymentIntent.payment_method ?? '',
          }
        )
        if (error) throw error
        else if (purchase) {
          console.debug('Purchase success', purchase)
          localStorage.setItem('pendingPurchase', undefined)
          localStorage.setItem('purchasedItem', item)
          navigate(`/purchase/${purchase.purchase.id}/`)
        } else {
          throw 'Missing purchase object or item object'
        }
      }
    } catch (error) {
      setError(handleError(error))
    } finally {
      setIsLoading(false)
    }
  }
  return {
    accountPurchase,
    accountlessPurchase,
  }
}
