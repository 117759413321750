import React, { useContext, useEffect } from 'react'
import { ThemeProvider } from '@mui/system'
import { createUseStyles } from 'react-jss'
import recrqlColors from '../../theme/recrql/colors'
import recrqlTheme from '../../theme/recrql/theme'
import { useIsMobile } from '../../utils/useWindowDimensions'
import Typography from '../../components/Typography'
import LayoutContext from '../../contexts/LayoutContext'
import { ReCRQLLogo } from '../../components/Logo'

export const useAuthContainerStyles = createUseStyles({
  auth: {
    backgroundColor: recrqlColors.background.background,
    padding: 40,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  authMobile: {
    width: '100%',
  },
  authDesktop: {
    position: 'relative',
    margin: '21px 0',
    width: 440,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transform: 'translate(-50%,0)',
    left: '50%',
  },
  title: {
    margin: '54px 0 36px 0',
  },
  content: {},
  '@media screen and (max-width: 1100px)': {
    authDesktop: {
      marginTop: '14%',
    },
  },
})

type Props = {
  children: React.ReactNode
  title: string | React.ReactElement
}

export default function AuthContainer({ children, title }: Props) {
  const styles = useAuthContainerStyles()
  const isMobile = useIsMobile()
  const [_, setLayoutContext] = useContext(LayoutContext)
  useEffect(() => {
    setLayoutContext((p) => ({
      ...p,
      contentStyle: { display: isMobile ? 'flex' : undefined },
    }))
  }, [isMobile])
  useEffect(() => {
    return () => {
      setLayoutContext((p) => ({ ...p, contentStyle: undefined }))
    }
  }, [])
  return (
    <ThemeProvider theme={recrqlTheme}>
      <div
        className={[
          styles.auth,
          isMobile ? styles.authMobile : styles.authDesktop,
        ].join(' ')}
      >
        {!isMobile && <ReCRQLLogo size="medium" />}
        <Typography className={styles.title} variant="recrql-title-large">
          {title}
        </Typography>
        {children}
      </div>
    </ThemeProvider>
  )
}
