import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  ExpandLess,
  ExpandMore,
  ZoomIn,
} from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { colors, styles } from '../theme'
import Touchable from './Touchable'
import Typography from './Typography'
import { useCommonStyles } from '../styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import useWindowDimensions, { useIsMobile } from '../utils/useWindowDimensions'
import styled from 'styled-components'
import zIndexes from '../utils/zIndexes'

type StyleProps = {
  isMobile: boolean
}
const useStyles = createUseStyles<string, StyleProps>({
  left: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  images: {
    maxHeight: 676,
    overflowY: 'auto',
  },
  main: {
    position: 'relative',
    marginLeft: ({ isMobile }) => (isMobile ? 'inherit' : 23),
  },
  img: {
    width: '74.06px',
    display: 'flex',
    backgroundColor: colors.backgroundSecondary,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
  },
  mainImg: {
    width: ({ isMobile }) => (isMobile ? '100%' : 490),
  },
  zoom: {
    zIndex: zIndexes.carouselZoom,
    position: 'absolute',
    right: 22,
    top: 22,
    color: '#000',
    transition: '0.5s ease-out',
    '&:hover': {
      color: 'rgb(100,100,100)',
    },
  },
  zoomIcon: {
    fontSize: '2rem !important',
  },
  imgNavButton: {
    position: 'absolute',
    width: 74.06,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgNavButtonTop: {
    top: -29,
  },
  imgNavButtonBottom: {
    bottom: -29,
  },
  bottom: {
    display: 'flex',
  },
})

const CarouselContainer = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => `
  display: flex;
  padding-bottom: ${isMobile ? 'inherit' : '34px'};
  .counter-selector {
    position: absolute;
    width: 490px;
    bottom: -38px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .minified-selector {
    position: absolute;
    bottom: 24px;
    width: 100%;
    z-index: ${zIndexes.carouselMinifiedSelector};
    display: flex;
    justify-content: center;
  }
  .selector-ellipse {
    background-color: #fff;
    width: 9px;
    height: 9px;
    border-radius: 4.5px;
    opacity: 0.5;
  }
  .selected-ellipse {
    opacity: 1.0;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.backgroundSecondary};
    height: 676px;
  }
  ${styles.carousel}
`
)

export type CarouselProps<T> = {
  selectedIndex: number
  images: T[]
  thumbSrc: (src: T) => string
  imgSrc: (src: T) => string
  bigPicSrc: (src: T) => string
  onSelect: (i: number) => void
  selector?: 'minified' | 'counter'
}

function ImgNavButton({
  className,
  isDisabled,
  icon,
  onClick,
}: {
  className?: string
  isDisabled: boolean
  onClick: () => void
  icon: React.ReactNode
}) {
  const isMobile = useIsMobile()
  const styles = useStyles({ isMobile })
  return (
    <div className={[styles.imgNavButton, className].join(' ')}>
      <Touchable isDisabled={isDisabled} onClick={onClick}>
        {icon}
      </Touchable>
    </div>
  )
}

const THUMB_HEIGHT = 104
const THUMB_PADDING = 12
export default function Carousel<T extends { url: string }>({
  selectedIndex,
  images,
  onSelect,
  thumbSrc,
  imgSrc,
  bigPicSrc,
  selector,
}: CarouselProps<T>) {
  const canNavTo = (index: number) => {
    return index >= 0 && index < images.length
  }
  const canNav = (direction: 'back' | 'forward') =>
    canNavTo(selectedIndex + (direction == 'back' ? -1 : 1))
  const navTo = (index: number) => {
    if (!canNavTo(index)) return
    onSelect(index)
    scrollTo(index)
  }
  const nav = (direction: 'back' | 'forward') => {
    navTo(selectedIndex + (direction == 'back' ? -1 : 1))
  }
  const scrollTo = (index: number) => {
    imagesRef.current?.scrollTo(0, (THUMB_HEIGHT + THUMB_PADDING) * index)
  }
  const scroll = (direction: 'back' | 'forward') => {
    if (!canScroll?.[direction]) return
    imagesRef.current?.scrollBy(
      0,
      (THUMB_HEIGHT + THUMB_PADDING) * (direction == 'back' ? -1 : 1)
    )
  }
  const isMobile = useIsMobile()
  const styles = useStyles({ isMobile })
  const imagesRef = useRef<HTMLHeadingElement>(null)
  function handleOnScroll() {
    if (!imagesRef.current) return
    const { scrollHeight, scrollTop, offsetHeight } = imagesRef.current
    const totalOffset = offsetHeight + scrollTop
    setCanScroll({
      back: scrollTop > 0,
      forward: totalOffset < scrollHeight - 0.5,
    })
  }
  const [canScroll, setCanScroll] = useState<
    { [direction in 'back' | 'forward']: boolean } | undefined
  >(undefined)
  useEffect(() => {
    if (images.length > 0 && !canScroll && imagesRef.current) {
      handleOnScroll()
    }
  }, [images.length, imagesRef.current])
  const [bigPicIsVisible, setBigPicIsVisible] = useState(false)
  const commonStyles = useCommonStyles()
  const windowDimensions = useWindowDimensions()
  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined)
  useEffect(() => swiper?.slideTo(selectedIndex), [selectedIndex])
  return (
    <>
      <CarouselContainer className="carousel" isMobile={isMobile}>
        {!isMobile && (
          <div className={styles.left}>
            <ImgNavButton
              className={styles.imgNavButtonTop}
              isDisabled={!canScroll?.['back']}
              icon={<ExpandLess />}
              onClick={() => scroll('back')}
            />
            <div
              className={styles.images}
              ref={imagesRef}
              onScroll={handleOnScroll}
            >
              {images.map((img, index) => (
                <Touchable
                  key={index + img.url}
                  className={styles.img}
                  onClick={() => navTo(index)}
                >
                  <img src={thumbSrc(img)} width={74.06} />
                </Touchable>
              ))}
            </div>
            <ImgNavButton
              className={styles.imgNavButtonBottom}
              isDisabled={!canScroll?.['forward']}
              icon={<ExpandMore />}
              onClick={() => scroll('forward')}
            />
          </div>
        )}
        <div className={styles.main}>
          <div
            className={isMobile ? '' : commonStyles.touchable}
            onClick={() => !isMobile && setBigPicIsVisible(true)}
          >
            {!isMobile && (
              <div className={styles.zoom}>
                <ZoomIn className={styles.zoomIcon} />
              </div>
            )}
            <Swiper
              style={{
                width: isMobile ? windowDimensions.width : '490px',
              }}
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={(swiper) => navTo(swiper.activeIndex)}
              onSwiper={(swiper) => setSwiper(swiper)}
              tabIndex={selectedIndex}
            >
              {images.map((img, index) => (
                <SwiperSlide key={index + ''}>
                  <img className={styles.mainImg} src={imgSrc(img)} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {(selector == 'minified' || (selector == undefined && isMobile)) && (
            <div className="minified-selector">
              {images.map((i, index) => (
                <div
                  key={`selector-ellipse-${index}`}
                  style={{
                    marginLeft: index > 0 ? '4px' : '0px',
                    marginRight: index < images.length - 1 ? '4px' : '0px',
                  }}
                  className={[
                    'selector-ellipse',
                    index == selectedIndex ? 'selected-ellipse' : '',
                  ].join(' ')}
                />
              ))}
            </div>
          )}
          {(selector == 'counter' || (selector == undefined && !isMobile)) && (
            <div className="counter-selector">
              <Touchable
                isDisabled={!canNav('back')}
                onClick={() => nav('back')}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: 14,
                    position: 'relative',
                  }}
                />
              </Touchable>
              <Typography variant="detail-body2">
                {selectedIndex + 1}/{images.length}
              </Typography>
              <Touchable
                isDisabled={!canNav('forward')}
                onClick={() => nav('forward')}
              >
                <ArrowForwardIos
                  sx={{
                    fontSize: 14,
                    left: 2,
                    position: 'relative',
                  }}
                />
              </Touchable>
            </div>
          )}
        </div>
      </CarouselContainer>
      <BigPic
        isVisible={bigPicIsVisible}
        onClose={() => setBigPicIsVisible(false)}
        canNav={canNav}
        nav={nav}
        selectedIndex={selectedIndex}
        images={images.map((i) => bigPicSrc(i))}
      />
    </>
  )
}

const useBigPicStyles = createUseStyles({
  bigPic: {
    width: '100%',
    backgroundColor: colors.backgroundPrimary,
    position: 'absolute',
    top: 0,
    zIndex: zIndexes.carouselBigPic,
    left: 0,
    transition: '0.6s ease-out',
    overflowY: 'scroll',
    height: '100%',
  },
  visible: {
    visibility: 'visible',
    opacity: 1,
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
  },
  controls: {
    position: 'fixed',
    justifyContent: 'flex-end',
    display: 'flex',
    top: 20,
    right: 20,
  },
  close: {
    fontSize: '2rem !important',
  },
  selector: {
    display: 'flex',
    marginRight: '2rem',
    alignItems: 'center',
  },
  selectorArrow: {
    fontSize: '1.5rem !important',
  },
  selectorNumbers: {
    margin: '0 6px',
  },
})

type BigPicProps = {
  onClose: () => void
  isVisible: boolean
  canNav: (direction: 'back' | 'forward') => boolean
  nav: (direction: 'back' | 'forward') => void
  selectedIndex: number
  images: string[]
}

function BigPic({
  isVisible,
  onClose,
  canNav,
  nav,
  selectedIndex,
  images,
}: BigPicProps) {
  const img = images[selectedIndex]
  const styles = useBigPicStyles()
  return (
    <div
      className={[
        styles.bigPic,
        isVisible ? styles.visible : styles.hidden,
      ].join(' ')}
    >
      <div className={styles.controls}>
        <div className={styles.selector}>
          <Touchable isDisabled={!canNav('back')} onClick={() => nav('back')}>
            <ArrowBackIos
              className={styles.selectorArrow}
              sx={{
                position: 'relative',
              }}
            />
          </Touchable>
          <Typography className={styles.selectorNumbers} variant="detail-body2">
            {selectedIndex + 1}/{images.length}
          </Typography>
          <Touchable
            isDisabled={!canNav('forward')}
            onClick={() => nav('forward')}
          >
            <ArrowForwardIos
              className={styles.selectorArrow}
              sx={{
                left: 2,
                position: 'relative',
              }}
            />
          </Touchable>
        </div>
        <Touchable onClick={onClose}>
          <Close className={styles.close} />
        </Touchable>
      </div>
      <img src={img} width="100%" />
    </div>
  )
}
