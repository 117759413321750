import {
  AccountPurchaseRequest,
  AccountPurchaseResponse,
  CompletePurchaseError,
  AccountlessPurchaseResponse,
  AccountlessPurchaseRequest,
} from './../../api/purchases/types'
import {
  StripeError,
  SetupIntent,
  PaymentIntent,
  SetupIntentResult,
  PaymentIntentResult,
  Stripe,
} from '@stripe/stripe-js'
import { t } from 'i18next'
import { Item } from '../../api/items/types'
import {
  accountlessPurchase,
  accountPurchase,
} from '../../api/purchases/endpoints'

async function handlePurchaseError(
  stripe: Stripe,
  error?: CompletePurchaseError
): Promise<PaymentIntent['id'] | undefined> {
  if (
    error?.detail == 'Action required' &&
    error.payment_intent_client_secret
  ) {
    const clientSecret = error.payment_intent_client_secret
    const paymentIntent = await handlePaymentIntentResult(
      {
        error: undefined,
        paymentIntent: {
          status: 'requires_action',
          client_secret: clientSecret,
          id: '',
          amount: 0,
          object: 'payment_intent',
          capture_method: 'automatic',
          canceled_at: 0,
          cancellation_reason: 'abandoned',
          created: 0,
          currency: '',
          confirmation_method: 'automatic',
          description: '',
          last_payment_error: null,
          livemode: false,
          next_action: null,
          payment_method: '',
          payment_method_types: [],
          receipt_email: '',
          setup_future_usage: null,
          shipping: null,
        },
      },
      { stripe }
    )
    return paymentIntent?.id
  }
  return undefined
}

export async function handleAccountPurchase(
  stripe: Stripe,
  item_id: Item['id'],
  body: AccountPurchaseRequest
): Promise<[AccountPurchaseResponse?, CompletePurchaseError?]> {
  const [purchase, purchaseError] = await accountPurchase(item_id, body)
  const payment_intent_id = await handlePurchaseError(stripe, purchaseError)
  if (payment_intent_id) {
    return handleAccountPurchase(stripe, item_id, {
      ...body,
      payment_intent_id,
    })
  } else {
    return [purchase, purchaseError]
  }
}

export async function handleAccountlessPurchase(
  stripe: Stripe,
  item_id: Item['id'],
  body: AccountlessPurchaseRequest
): Promise<[AccountlessPurchaseResponse?, CompletePurchaseError?]> {
  const [purchase, purchaseError] = await accountlessPurchase(item_id, body)
  const payment_intent_id = await handlePurchaseError(stripe, purchaseError)
  if (payment_intent_id) {
    return handleAccountlessPurchase(stripe, item_id, {
      ...body,
      payment_intent_id,
    })
  } else {
    return [purchase, purchaseError]
  }
}

type PaymentIntentResultOptions = {
  stripe: Stripe
}
export async function handlePaymentIntentResult(
  { error, paymentIntent }: PaymentIntentResult,
  { stripe }: PaymentIntentResultOptions
): Promise<PaymentIntent | undefined> {
  if (error) {
    throw error
  } else if (paymentIntent?.status == 'requires_action') {
    if (paymentIntent?.next_action) {
      handleNextAction(paymentIntent.next_action)
    } else if (paymentIntent?.client_secret) {
      const result = await stripe.handleCardAction(paymentIntent.client_secret)
      return handlePaymentIntentResult(result, { stripe })
    } else {
      throw 'Unhandled requires_action status.'
    }
  } else if (paymentIntent?.status == 'requires_confirmation') {
    return paymentIntent
    // throw 'Payment intent requires confirmation.';
  } else if (paymentIntent?.status == 'succeeded') {
    return paymentIntent
  } else {
    throw 'Unhandled payment intent.'
  }
}

export function handleSetupIntentResult({
  error,
  setupIntent,
}: SetupIntentResult): SetupIntent | undefined {
  if (error) {
    throw error
  } else if (setupIntent?.status == 'succeeded') {
    return setupIntent
  } else if (setupIntent?.status == 'requires_action') {
    handleNextAction(setupIntent.next_action)
    return undefined
  } else {
    throw 'Unhandled stripe intent'
  }
}

export function handleNextAction(
  nextAction: (SetupIntent.NextAction | PaymentIntent.NextAction) | null
) {
  const url = nextAction?.redirect_to_url?.url
  console.debug('Handle next action', nextAction)
  if (url) {
    const iframe = document.createElement('iframe')
    iframe.src = url
    iframe.width = '600px'
    iframe.height = '400px'
    document.getElementById('checkout-confirm')?.appendChild(iframe)
  }
}
export function handleError(error: unknown): string {
  return (
    (error as StripeError).message ||
    (error as CompletePurchaseError).detail ||
    (typeof error === 'string'
      ? error + ''
      : (error as Error).message || t('checkout.unknownPaymentError'))
  )
}
