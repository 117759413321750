import styled from 'styled-components'
import { colors, styles } from '../../../theme'

const Divider = styled.div<{ withoutMargin?: boolean }>(
  ({ withoutMargin }) => `
  height: 1px;
  width: 100%;
  background-color: ${colors.strokePrimary};
  ${!withoutMargin ? `margin: 12px 0;` : ''}
  ${styles.divider}
`
)

export default Divider
