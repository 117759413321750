import React from 'react'
import { colors, properties, styles } from '../../../theme'
import { CheckState, FilterKey, ItemFiltersState } from './types'
import FilterDropdown from './FilterDropdown'
import FilterExpander from './FilterExpander'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Typography from '../../../components/Typography'
import { template } from '../detail/utils'

const layoutSettings = properties.layoutSettings.itemFiltersDesktopView

type Props = {
  state: ItemFiltersState
  handleToggle: (key: FilterKey) => void
  handleCheckToggle: (key: FilterKey, checkState: CheckState) => void
}

const Container = styled.div.attrs({
  className: 'item-filters-desktop',
})`
  display: flex;
  border-bottom: 1px solid ${colors.strokePrimary};
  .filter-expander .contents {
    max-height: 300px;
    overflow: auto;
  }
  .filter-dropdown .content {
    max-height: 250px;
    overflow: auto;
  }
  ${styles.itemFiltersDesktopView}
`

const LabelTypography = styled(Typography)`
  margin-right: 30px;
  text-transform: initial;
`

export default function ItemFiltersDesktopView({
  state,
  handleToggle,
  handleCheckToggle,
}: Props) {
  const { t } = useTranslation()
  const keys = Object.keys(state) as [keyof ItemFiltersState]
  return (
    <Container>
      {layoutSettings.label && (
        <LabelTypography variant="general-button2">
          {t(layoutSettings.label)}
        </LabelTypography>
      )}
      {keys.map((key, index) => {
        if (template == 'outnorth') {
          return (
            <FilterExpander
              // @ts-expect-error: 123
              key={key + index}
              state={state[key]}
              // @ts-expect-error: 123
              onToggle={() => handleToggle(key)}
              onCheckToggle={({ label, isChecked }) => {
                // @ts-expect-error: 123
                handleCheckToggle(key, {
                  label,
                  isChecked,
                })
              }}
            />
          )
        }
        return (
          <FilterDropdown
            // @ts-expect-error: 123
            key={key + index}
            state={state[key]}
            // @ts-expect-error: 123
            onToggle={() => handleToggle(key)}
            onCheckToggle={(label, isChecked) => {
              // @ts-expect-error: 123
              handleCheckToggle(key, {
                label,
                isChecked,
              })
            }}
          />
        )
      })}
    </Container>
  )
}
