import React from 'react'
import styled from 'styled-components'
import Typography from '../../components/Typography'
import recrqlColors from '../../theme/recrql/colors'

const Container = styled.div`
  border: 1px solid ${recrqlColors.neutralGrey.background};
  display: flex;
  flex-direction: column;
  padding: 12px 25px;
  > :first-child {
    margin-bottom: 3px;
  }
  > :not(:first-child) {
    margin-top: 7px;
  }
`

type Props = {
  title?: string
  name?: string
  street?: string
  postalCode?: string
  city?: string
}

export default function AddressInfo({
  title,
  name,
  street,
  postalCode,
  city,
}: Props) {
  return (
    <Container>
      {!!title && (
        <Typography variant="recrql-title-medium">{title}</Typography>
      )}
      {!!name && <Typography variant="recrql-label-medium">{name}</Typography>}
      {!!street && (
        <Typography variant="recrql-label-medium">{street}</Typography>
      )}
      {!!postalCode && !!city && (
        <Typography variant="recrql-label-medium">
          {postalCode} {city}
        </Typography>
      )}
    </Container>
  )
}
