import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Search from './SearchInput'
import { useIsMobile } from '../../../utils/useWindowDimensions'
import NavMobile from './NavMobile'
import NavDesktop from './NavDesktop'
import NavMenu from './NavMenu'
import styled from 'styled-components'
import { colors } from '../../../theme'
import styles from '../../../theme/styles'
import Usps from './Usps'
import zIndexes from '../../../utils/zIndexes'
import SearchDrawer from './SearchDrawer'

const SuperContainer = styled.div.attrs({
  id: 'nav-super-container',
})`
  position: sticky;
  background-color: ${colors.backgroundPrimary};
  top: 0px;
  width: 100%;
  z-index: ${zIndexes.navContainer};
  ${styles.nav.superContainer}
`

export const navHeight = () => {
  const nheight =
    document.getElementById('nav-super-container')?.offsetHeight ?? 0
  const uheight = document.getElementById('usps')?.offsetHeight ?? 0
  const scrollTop = document.getElementById('layout')?.scrollTop ?? 0
  const height = nheight + Math.max(0, uheight - scrollTop)
  return height
}

export default function NavigationView() {
  const isMobile = useIsMobile()
  const [navMenuOpen, setNavMenuOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <>
      <Usps />
      <SuperContainer>
        {isMobile ? (
          <NavMobile
            onClickSearch={() => setSearchOpen((p) => !p)}
            onClickNavMenu={() => setNavMenuOpen((p) => !p)}
          />
        ) : (
          <NavDesktop
            onClickSearch={() => setSearchOpen((p) => !p)}
            searchOpen={searchOpen}
          />
        )}
      </SuperContainer>
      {isMobile ? (
        <SearchDrawer
          belowNav={false}
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          onSubmit={(query) => {
            navigate(`/search/${query}/`)
            setSearchOpen(false)
          }}
        />
      ) : null}
      {isMobile ? (
        <NavMenu
          open={navMenuOpen}
          onClose={() => setNavMenuOpen(false)}
          onNavigate={(path) => {
            setNavMenuOpen(false)
            navigate(path)
          }}
        />
      ) : null}
    </>
  )
}
