import { ArrowBackIos } from '@mui/icons-material'
import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import { CombinedLogo, ReCRQLLogo } from '../../components/Logo'
import Touchable from '../../components/Touchable'
import LayoutContext, {
  LayoutContextProvider,
} from '../../contexts/LayoutContext'
import { useIsMobile } from '../../utils/useWindowDimensions'
import NavigationView from './navigation/NavigationView'
import styled from 'styled-components'
import { properties, styles } from '../../theme'
import zIndexes from '../../utils/zIndexes'

type LayoutDisplay = 'attached' | 'detached'

const StyledLayout = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => `
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .content {
    flex: 1;
  }
  .go-back {
    z-index: ${zIndexes.layoutGoBack};
    display: flex;
    align-items: center;
    margin: ${isMobile ? 'unset' : '50px 0 30px 50px'};
    position: ${isMobile ? 'relative' : 'absolute'};
    padding: ${isMobile ? '25px' : 'unset'};
    .logo {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  ${styles.layout({ isMobile })}
`
)

type Props = {
  display: LayoutDisplay
}

export default function Layout({ display }: Props) {
  const isMobile = useIsMobile()
  return (
    <LayoutContextProvider>
      <StyledLayout isMobile={isMobile} id="layout">
        {display === 'detached' && (
          <DetachedView>
            <Outlet />
          </DetachedView>
        )}
        {display === 'attached' && (
          <AttachedView>
            <Outlet />
          </AttachedView>
        )}
      </StyledLayout>
    </LayoutContextProvider>
  )
}

function Content({ children }: { children: React.ReactNode }) {
  const [{ contentStyle }] = useContext(LayoutContext)
  return (
    <div className="content" id="content" style={contentStyle}>
      {children}
    </div>
  )
}

function AttachedView({ children }: { children: React.ReactNode }) {
  return (
    <>
      <NavigationView />
      <Content>{children}</Content>
      <Footer />
    </>
  )
}

function DetachedView({ children }: { children: React.ReactNode }) {
  return (
    <>
      <GoBackButton />
      <Content>{children}</Content>
    </>
  )
}

function GoBackButton() {
  const [{ handleGoBack: handleGoBackCB, backButtonDisabled }] =
    useContext(LayoutContext)
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  function handleGoBack() {
    if (handleGoBackCB) {
      handleGoBackCB()
    } else {
      navigate('/')
    }
  }
  return (
    <Touchable
      className="go-back"
      isDisabled={backButtonDisabled}
      onClick={handleGoBack}
    >
      <ArrowBackIos sx={{ marginRight: '10px', fontSize: '1.2rem' }} />
      {!isMobile && <CombinedLogo recrqlSize="small" partnerSize="small" />}
      {isMobile &&
        (properties.layoutSettings.goBack.mobileCombinedLogo ? (
          <CombinedLogo
            className="logo"
            recrqlSize="small"
            partnerSize="small"
          />
        ) : (
          <ReCRQLLogo size="medium" className="logo" />
        ))}
    </Touchable>
  )
}
