import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TextBody from '../../../components/TextBody'
import Typography from '../../../components/Typography'
import DescriptiveTypography from './DescriptiveTypography'
import Divider from './Divider'
import PurchaseButton from './PurchaseButton'
import useItem from './useItem'
import { partner, template, useIsSmallScreen } from './utils'

const StyledSellerContainer = styled.div<{ isSmallScreen: boolean }>(
  ({ isSmallScreen }) => `
  display: flex;
  flex-direction: column;
  ${
    isSmallScreen
      ? `
    width: 100%;
    align-items: center;
  `
      : ''
  }
`
)

export default function SellerContainer() {
  const { item } = useItem()
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  if (!item) return null
  return (
    <StyledSellerContainer
      isSmallScreen={isSmallScreen}
      className="seller-container"
    >
      {template == 'outnorth' ? (
        <>
          <Typography variant="detail-body1">
            {t('item.seller')}: {item.listing.seller_first_name}
          </Typography>
          <TextBody
            variant="detail-body1"
            buttonVariant="detail-link"
            text={`“${item.listing.condition_description}”`}
            truncatedText={`“${item.listing.condition_description.substring(0, 100)}...”`}
          />
        </>
      ) : (
        <>
          <Divider />
          <DescriptiveTypography variant="detail-subtitle2">
            {t('item.seller')}:{' '}
            <Typography variant="detail-subtitle1">
              {item?.listing.seller_first_name}
            </Typography>
          </DescriptiveTypography>
          <DescriptiveTypography variant="detail-subtitle2">
            {t('item.condition.label')}:{' '}
            {t(`item.condition.${item?.listing.condition}`)}
          </DescriptiveTypography>
          {item.listing.condition_description && (
            <DescriptiveTypography variant="detail-body1">
              “{item.listing.condition_description}”
            </DescriptiveTypography>
          )}
        </>
      )}
      {(template == 'houdini' || template === 'kappahl') && (
        <>
          <DescriptiveTypography variant="detail-subtitle2">
            {t('item.rrp')}:{' '}
            <Typography variant="detail-ogprice">
              {t('item.price', { val: item.original_list_price })}
            </Typography>
          </DescriptiveTypography>
          <DescriptiveTypography variant="detail-subtitle2">
            {t('item.salePrice')}:{' '}
            <Typography variant="detail-subtitle1">
              {t('item.price', { val: item.listing.price })}
            </Typography>
          </DescriptiveTypography>
          <PurchaseButton />
        </>
      )}
    </StyledSellerContainer>
  )
}
