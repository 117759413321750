import React from 'react'
import { useIsMobile } from '../../../utils/useWindowDimensions'
import styled from 'styled-components'
import Drawer, { DrawerProps } from '../../../components/Drawer'
import SearchInput from './SearchInput'
import { colors } from '../../../theme'

const StyledSearchDrawer = styled(Drawer).attrs(({ className, ...attrs }) => ({
  ...attrs,
  className: ['search-drawer', className].join(' '),
}))<{ isMobile: boolean }>(
  ({ isMobile }) => `
  .MuiPaper-root {
    background-color: ${colors.backgroundPrimary};
  }
  .close-button {
    margin: 0 !important;
    background-color: ${colors.backgroundSecondary};
  }
`
)

type Props = {
  belowNav: boolean
  onSubmit?: (query: string) => void
} & Omit<DrawerProps, 'belowNav'>

export default function SearchDrawer({ onSubmit, ...props }: Props) {
  const isMobile = useIsMobile()
  return (
    <StyledSearchDrawer closeButton anchor="top" isMobile={isMobile} {...props}>
      <SearchInput onSubmit={onSubmit} />
    </StyledSearchDrawer>
  )
}
