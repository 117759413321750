import moment from 'moment'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import {
  getMe,
  getTokenPair as getTokenPairEP,
  refreshToken as refreshTokenEP,
} from '../api/users/endpoints'
import { GetTokenPairBody, TokenPair, UserMe } from '../api/users/types'
import AuthContext from '../contexts/AuthContext'
import * as localStorage from './localStorage'

const ACCESS_TOKEN_LIFETIME = 5 * 60
const REFRESH_TOKEN_LIFETIME = 24 * 60 * 60

export interface Auth {
  tokenPair: TokenPair
  lastRefresh: number
  me: UserMe
}

/**
 * @throws {LoginError}
 */
export async function login(body: GetTokenPairBody): Promise<Auth> {
  const tokenPair = await getTokenPairEP(body)
  const me = await getMe(tokenPair.access)
  return setAuth({ tokenPair, me })
}

export async function register(tokenPair: TokenPair): Promise<Auth> {
  const me = await getMe(tokenPair.access)
  return setAuth({ tokenPair, me })
}

export async function refreshToken({ tokenPair }: Auth): Promise<Auth> {
  const { access } = await refreshTokenEP(tokenPair)
  const me = await getMe(access)
  return setAuth({ me, tokenPair: { refresh: tokenPair.refresh, access } })
}

export async function authenticate(): Promise<Auth> {
  try {
    let auth = localStorage.getItem('auth')
    if (auth) {
      const diff = moment.utc().diff(moment.unix(auth.lastRefresh)) / 1000
      if (diff > REFRESH_TOKEN_LIFETIME) {
        throw 'Refresh token expired'
      } else if (diff > ACCESS_TOKEN_LIFETIME) {
        auth = await refreshToken(auth)
      }
      return auth
    }
    throw 'No auth found'
  } catch (error) {
    console.log('[auth.authenticate]', error)
    unauthenticate()
    throw 'Unauthorized'
  }
}

export async function isLoggedIn(): Promise<boolean> {
  try {
    await authenticate()
    return true
  } catch {
    return false
  }
}

function setAuth(authInfo: { tokenPair: TokenPair; me: UserMe }): Auth {
  const lastRefresh = moment().unix()
  const auth = {
    tokenPair: authInfo.tokenPair,
    lastRefresh,
    me: authInfo.me,
  }
  localStorage.setItem('auth', auth)
  localStorage.setItem('isAuthenticated', true)
  return auth
}

function clearAuth() {
  localStorage.setItem('auth', undefined)
  localStorage.setItem('isAuthenticated', false)
}

export function unauthenticate() {
  return clearAuth()
}

export function useAuth() {
  const [{ auth }] = useContext(AuthContext)
  const queryClient = useQueryClient()
  function logout() {
    clearAuth()
    queryClient.clear()
  }
  return {
    auth,
    logout,
  }
}
