import React from 'react'
import styled from 'styled-components'
import Typography from '../../../components/Typography'

const NavLink = styled(Typography).attrs(({ className, ...attrs }) => ({
  className: ['nav-link', className].join(' '),
  ...attrs,
}))<{ active?: boolean }>`
  cursor: pointer;
  white-space: nowrap;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
`

export default NavLink
