import React from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { createUseStyles } from 'react-jss'
import { APIError } from '../api/common/types'
import Typography from './Typography'
import recrqlColors from '../theme/recrql/colors'
import { objectKeys } from '../utils/objectUtils'
import { Error, Visibility, VisibilityOff } from '@mui/icons-material'

type TextFieldPosition = 'left' | 'right' | 'stretch'

const useStyles = createUseStyles({
  textField: {
    marginBottom: '32px !important',
  },
  textFieldStretch: {
    width: '100% !important',
  },
  textFieldHalf: {
    width: 'calc(50% - 8px) !important',
  },
  textFieldLeft: {
    marginRight: '8px !important',
  },
  textFieldRight: {
    marginLeft: '8px !important',
  },
  error: {
    color: recrqlColors.error.background,
  },
})

type FormFieldStateKey<TObj> = keyof TObj

export type FormFieldState<TObj> = {
  [key in FormFieldStateKey<TObj>]: FormField<FormFieldState<TObj>>
}

type FormField<TState> = {
  isDisabled?: boolean
  ignore?: boolean
  formatValue?: (value: string) => string
  isValid: (value: string, state: TState) => boolean
  isError?: (value: string, state: TState) => boolean
  value: string
  label: string
  type?: React.InputHTMLAttributes<unknown>['type']
  helperText?: (isError: boolean) => string
  name?: string
  isRequired?: boolean
  position?: TextFieldPosition
  style?: React.CSSProperties
  showPassword?: boolean
}

export function formIsValid<TObj>(state: FormFieldState<TObj>) {
  return (
    Object.keys(state).filter((k) => {
      const field = state[k as FormFieldStateKey<TObj>]
      return !field.ignore && !field.isValid(field.value, state)
    }).length == 0
  )
}

export function formObject<TObj>(
  state: FormFieldState<TObj>,
  previousValue: TObj
): TObj {
  return objectKeys(state).reduce(
    (obj, key) => ({ ...obj, [key]: state[key].value }),
    previousValue
  )
}

export function updateFormFields<
  TObj,
  TState extends FormFieldState<TObj>,
  TFieldKey extends keyof FormField<TState>,
  TFieldValue extends FormField<TState>[TFieldKey],
>(state: FormFieldState<TObj>, fieldKey: TFieldKey, fieldValue: TFieldValue) {
  return objectKeys(state).reduce(
    (obj, key) => ({
      ...obj,
      [key]: {
        ...state[key],
        [fieldKey]: fieldValue,
      },
    }),
    state
  )
}

type Props<TObj> = {
  state: FormFieldState<TObj>
  onChange?: (state: FormFieldState<TObj>) => void
  textFieldClassName?: string
  validationError?: APIError<TObj>
  errorStyle?: React.CSSProperties
  onChangeError?: (error: APIError<TObj>) => void
}
export default function Form<TObj>({
  state,
  onChange,
  onChangeError,
  textFieldClassName,
  validationError,
  errorStyle,
}: Props<TObj>) {
  const styles = useStyles()
  return (
    <>
      {Object.keys(state).map((k, index) => {
        const key = k as FormFieldStateKey<TObj>
        const field = state[key]
        if (field.ignore) return null
        const isError =
          field.isError?.(field.value, state) ?? !!validationError?.[key]
        const error = validationError?.[key]?.map((e) => (
          <>
            {e}
            <br />
          </>
        ))
        const position = field.position ?? 'stretch'
        const { showPassword = false, type } = field
        function handleChangeField(
          fieldKey: keyof typeof field,
          value: unknown
        ) {
          onChange?.({
            ...state,
            [key]: { ...state[key], [fieldKey]: value },
          })
        }
        return (
          <TextField
            key={`form-field-${key}-${index}`}
            disabled={field.isDisabled}
            required={field.isRequired ?? true}
            InputProps={{
              endAdornment:
                isError || type == 'password' ? (
                  <InputAdornment position="end">
                    {isError ? (
                      <Error
                        htmlColor={recrqlColors.error.background}
                        sx={{ fontSize: '20px' }}
                      />
                    ) : type == 'password' ? (
                      <IconButton
                        aria-label="toggle password visibility"
                        onPointerDown={() =>
                          handleChangeField('showPassword', true)
                        }
                        onPointerUp={() =>
                          handleChangeField('showPassword', false)
                        }
                        onPointerLeave={() =>
                          handleChangeField('showPassword', false)
                        }
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                ) : null,
            }}
            className={[
              styles.textField,
              position == 'stretch'
                ? styles.textFieldStretch
                : styles.textFieldHalf,
              position == 'left'
                ? styles.textFieldLeft
                : position == 'right'
                  ? styles.textFieldRight
                  : '',
              textFieldClassName,
            ].join(' ')}
            name={field.name}
            style={field.style}
            label={field.label}
            value={field.value}
            type={type == 'password' && showPassword === true ? 'text' : type}
            error={isError}
            helperText={
              (error || field.helperText) && (
                <>{error ?? field.helperText?.(isError)}</>
              )
            }
            onChange={({ target: { value } }) => {
              const newValue = state[key].formatValue?.(value) ?? value
              handleChangeField('value', newValue)
              if (validationError) {
                onChangeError?.({
                  ...validationError,
                  [key]: undefined,
                })
              }
            }}
          />
        )
      })}
      {validationError?.detail && (
        <Typography
          variant="recrql-label-medium"
          style={errorStyle}
          className={styles.error}
        >
          {validationError.detail}
        </Typography>
      )}
    </>
  )
}
