import React from 'react'
import styled from 'styled-components'
import MUIDrawer, { DrawerProps as MUIDrawerProps } from '@mui/material/Drawer'
import { colors, styles } from '../theme'
import zIndexes from '../utils/zIndexes'
import Touchable from './Touchable'
import { Close } from '@mui/icons-material'
import { navHeight } from '../views/common/navigation/NavigationView'

export type DrawerProps = {
  belowNav?: boolean
  closeButton?: boolean
} & Omit<MUIDrawerProps, 'container'>

const StyledDrawer = styled(MUIDrawer)<DrawerProps>(
  ({ belowNav }) => `
  z-index: ${belowNav ? zIndexes.drawerBelowNav : zIndexes.drawerAboveNav} !important;
  .MuiBackdrop-root {
    z-index: ${zIndexes.backdrop};
  }
  .MuiDrawer-paperAnchorLeft {
    width: 80%;
  }
  .MuiPaper-root {
    background-color: ${colors.backgroundPrimary};
    margin-top: ${belowNav ? navHeight() : 0}px;
  }
  .close-button {
    margin: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }
`
)

export default function Drawer(props: DrawerProps) {
  return (
    <StyledDrawer container={document.getElementById('layout')} {...props}>
      {props.closeButton ? (
        <Touchable
          className="close-button"
          onClick={() => props.onClose?.({}, 'escapeKeyDown')}
        >
          <Close />
        </Touchable>
      ) : null}
      {props.children}
    </StyledDrawer>
  )
}
