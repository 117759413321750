import colorsJson from './colors.json'
import propertiesJson from './properties.json'
import stylesJson from './styles'
import { Colors, Properties, Styles, Typographies } from './types'
import typographiesJson from './typographies'

// @ts-expect-error: 123
const properties: Properties = propertiesJson
const colors: Colors = colorsJson
const styles: Styles = stylesJson
const typographies: Typographies = typographiesJson

export { colors, properties, styles, typographies }
