import React, { useContext, useEffect, useState } from 'react'
import { t } from 'i18next'
import { createUseStyles } from 'react-jss'
import Typography from '../../components/Typography'
import AuthContainer from './AuthContainer'
import Button from '../../components/Button'
import { useSearchParams } from '../../utils/useSearchParams'
import LayoutContext from '../../contexts/LayoutContext'
import { useNavigate } from 'react-router-dom'
import { resetPassword } from '../../api/users/endpoints'
import recrqlColors from '../../theme/recrql/colors'

const useStyles = createUseStyles({
  forgotPassword: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default function ForgotPasswordView() {
  const styles = useStyles()
  const [searchParams] = useSearchParams<{ email: string }>()
  const email = decodeURIComponent(searchParams.email)
  const [isLoading, setIsLoading] = useState(false)
  const [_, setLayoutContext] = useContext(LayoutContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (!email) {
      navigate('/login')
    }
    setLayoutContext((p) => ({ ...p, handleGoBack: () => navigate('/login') }))
    return () => setLayoutContext((p) => ({ ...p, handleGoBack: undefined }))
  }, [])
  const [error, setError] = useState('')
  async function handleResend() {
    setIsLoading(true)
    const [_, error] = await resetPassword(email)
    if (error) {
      setError(
        error.email?.at(0) ?? error.detail ?? t('forgotPassword.unknownError')
      )
    }
    setIsLoading(false)
  }
  return (
    <AuthContainer title={t('forgotPassword.title', { email })}>
      <div className={styles.forgotPassword}>
        <Typography variant="recrql-body-medium">
          {t('forgotPassword.description')}
        </Typography>
        {error && (
          <Typography
            variant="recrql-label-medium"
            style={{ marginTop: '16px', color: recrqlColors.error.background }}
          >
            {error}
          </Typography>
        )}
        <Button
          buttonStyle={{
            margin: '40px 0',
          }}
          type="primary"
          context="recrql"
          size="stretch"
          onClick={handleResend}
          text={isLoading ? t('button.sending') : t('button.resend')}
          isLoading={isLoading}
          state={isLoading ? 'disabled' : 'active'}
        />
      </div>
    </AuthContainer>
  )
}
