import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { resendVerificationEmail, verifyEmail } from '../../api/users/endpoints'
import { VerifyEmailBody } from '../../api/users/types'
import { useSearchParams } from '../../utils/useSearchParams'
import { useNavigate } from 'react-router-dom'
import AuthContainer from '../user/AuthContainer'
import { t } from 'i18next'
import { handleAPIError } from '../../api/common/utils'
import Typography from '../../components/Typography'
import recrqlColors from '../../theme/recrql/colors'
import { CircularProgress } from '@mui/material'
import Button from '../../components/Button'
import { properties } from '../../theme'
import useIsAbsoluteUri from '../../utils/useIsAbsoluteUri'

const useStyles = createUseStyles({
  verifyEmail: {},
})

export default function VerifyEmail() {
  const [{ id, token }] = useSearchParams<{ id: string; token: string }>()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)
  const styles = useStyles()
  const navigate = useNavigate()
  useEffect(() => {
    if (!id || !token) {
      navigate('/login')
    }
    verifyEmail({ user: id, token })
      .then(() => {
        const uri = properties.emailConfirmPath
        if (useIsAbsoluteUri(uri)) {
          window.location.replace(uri)
        } else {
          navigate('confirm')
        }
      })
      .catch((e) => setError(handleAPIError<VerifyEmailBody>(e)))
      .finally(() => setIsLoading(false))
  }, [])
  return (
    <AuthContainer
      title={
        <>
          {isLoading && (
            <>
              <>{t('verifyEmail.verifying')}</>
              <CircularProgress size="16px" style={{ marginLeft: '8px' }} />
            </>
          )}
          {!isLoading && error ? t('verifyEmail.verifyError') : ''}
        </>
      }
    >
      <div className={styles.verifyEmail}>
        {error && (
          <Typography
            variant="recrql-label-medium"
            style={{ color: recrqlColors.error.background }}
          >
            {error}
          </Typography>
        )}
      </div>
    </AuthContainer>
  )
}
