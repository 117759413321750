import React, { useEffect } from 'react'
import AppRoutes from './router/AppRoutes'
import Helmet from 'react-helmet'
import { t } from 'i18next'
import { properties } from './theme'

function App() {
  return (
    <>
      <Helmet>
        <link rel="icon" href={require(`./assets/${properties.favicon}`)} />
        <title>
          {t('helmet.app.title', { partner: properties.partner.name })}
        </title>
        <meta name="description" content={t('helmet.app.description')} />
        {/* <meta property="og:image" content="https://houdinisportswear.com/images/houdini_og-image_fallback_2.jpg"></meta> TODO: */}
        <meta
          name="google-site-verification"
          content="WQTDfKmlWlxT9_5wrpr9T3TsHc94Akf5duhNIMo2ACw"
        />
      </Helmet>
      <AppRoutes />
    </>
  )
}

export default App
