import React from 'react'
import Dropdown from '../../../components/Dropdown'
import { useTranslation } from 'react-i18next'
import { ItemFilterState } from './types'
import styled from 'styled-components'
import { getItemFilterCheckboxes } from './utils'
import Divider from '../detail/Divider'

const Container = styled.div.attrs({
  className: 'filter-dropdown',
})`
  position: relative;
  margin-right: 44px;
`

type Props = {
  state: ItemFilterState
  onToggle: () => void
  onCheckToggle: (label: string, isChecked: boolean) => void
}

export default function ItemFilter({ state, onToggle, onCheckToggle }: Props) {
  const { isExpanded, items, label } = state
  const { t } = useTranslation()
  if (!items.length) return null
  return (
    <Container>
      <Dropdown
        onToggle={onToggle}
        isExpanded={isExpanded}
        label={t(label)}
        items={getItemFilterCheckboxes(state, ({ label, isChecked }) =>
          onCheckToggle(label, isChecked)
        )}
      />
      {isExpanded && <Divider withoutMargin />}
    </Container>
  )
}
