import React from 'react'
import { colors, styles as themeStyles, properties } from '../theme'
import Touchable from './Touchable'
import Typography, { TypographyVariant } from './Typography'
import recrqlColors from '../theme/recrql/colors'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

const partnerButton = properties.layoutSettings.partnerButton

type StyleProps = {
  context: Context
  size: Size
  type: Type
  state: State
}

type Context = 'partner' | 'recrql'
type Size = 'stretch' | 'large' | 'medium' | 'small' | 'auto'
type Type = 'primary' | 'secondary' | 'link'
type State = 'active' | 'disabled'
export type ButtonProps = {
  isLoading?: boolean
  context?: Context
  state: State
  size: Size
  type: Type
  text: string
  onClick: () => void
  className?: string
  buttonStyle?: React.CSSProperties
  startIcon?: any
  variant?: TypographyVariant
}

export default function Button({
  state,
  size,
  type,
  text,
  onClick,
  context = 'partner',
  isLoading,
  buttonStyle,
  startIcon,
  className,
  variant: _variant,
}: ButtonProps) {
  const variant: TypographyVariant =
    _variant ??
    (context == 'partner'
      ? type == 'link'
        ? 'general-link'
        : 'general-button1'
      : 'recrql-button-medium')
  return (
    <StyledTouchable
      isDisabled={state == 'disabled'}
      context={context}
      type={type}
      size={size}
      state={state}
      className={className}
      onClick={onClick}
    >
      <div style={buttonStyle} className="button">
        {!!startIcon && !isLoading && (
          <div className="start-icon">{startIcon}</div>
        )}
        {isLoading && (
          <CircularProgress
            size={type == 'link' ? '16px' : '18px'}
            sx={{
              marginRight: '8px',
              color:
                type == 'link'
                  ? recrqlColors.secondaryDark.background
                  : type == 'secondary'
                    ? recrqlColors.primaryDark.background
                    : recrqlColors.neutralLight.background,
            }}
          />
        )}
        <Typography className="text" variant={variant}>
          {text}
        </Typography>
      </div>
    </StyledTouchable>
  )
}

const StyledTouchable = styled(Touchable)<StyleProps>(
  ({ context, type, size }) => `
  width: ${size == 'stretch' ? '100%' : 'unset'};
  .button {
    background-color: ${
      type == 'link'
        ? 'unset'
        : context == 'partner'
          ? type == 'primary'
            ? colors.primaryAccent
            : '#fff'
          : type == 'primary'
            ? recrqlColors.primaryDark.background
            : recrqlColors.primaryLight.background
    };
    padding: ${context == 'recrql' ? '10px' : (partnerButton.padding ?? '16px')};
    align-items: center;
    justify-content: center;
    display: flex;
    width: ${size == 'stretch' || size == 'auto' ? 'auto' : size == 'large' ? '349px' : size == 'medium' ? '308px' : '94px'};
    border: ${
      context != 'recrql' && type == 'secondary' ? '1px solid #979797' : '0px'
    };
    border-radius: ${context == 'recrql' ? '100px' : (partnerButton.borderRadius ?? '0px')};
    ${themeStyles.button}
  }
  .text {
    color: ${
      context == 'partner'
        ? type == 'primary'
          ? '#fff'
          : colors.textPrimary
        : type == 'primary'
          ? recrqlColors.primaryDark.text
          : recrqlColors.primaryLight.text
    };
  }
`
)
