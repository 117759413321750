import React from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'
import Typography from '../../components/Typography'
import { Item, MyItem } from '../../api/items/types'
import recrqlColors from '../../theme/recrql/colors'
import { colors } from '../../theme'
import { getThumb } from '../../api/items/utils'
import { Purchase } from '../../api/purchases/types'

type StyleProps = Pick<Props, 'context'>

const useStyles = createUseStyles<string, StyleProps>({
  priceInfo: {
    backgroundColor: ({ context }) =>
      context == 'checkout'
        ? recrqlColors.background.background
        : colors.backgroundPrimary,
    border: '1px solid ' + recrqlColors.neutralGrey.background,
  },
  thumb: {
    width: 52,
    borderRadius: 5,
  },
  info: {
    flex: 1,
    padding: '18px 65px 50px 50px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomLine: {
    height: 1,
    width: '100%',
    margin: '20px 0',
    backgroundColor: recrqlColors.neutralDark.background,
  },
  top: {
    padding: '35px 50px 26px 50px',
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: recrqlColors.neutralGrey.background,
  },
  topText: {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
  },
})

type Context = 'checkout' | 'checkout-confirm'

type Props = {
  className?: string
  item: Item
  purchase?: Purchase
  context: Context
}

export default function PriceInfo({
  context,
  className,
  item,
  purchase,
}: Props) {
  const { t } = useTranslation()
  const styles = useStyles({ context })
  const deliveryPrice = item.listing.delivery_price
  const vat = deliveryPrice * 0.2
  const thumb = getThumb(item)
  const subtotal = item.listing.price
  const totalPrice = item.listing.total_price
  const title = item.title
  const color = item.color
  const size = item.size
  const deliveryMethod = purchase?.listing_data?.delivery_method

  return (
    <div className={[styles.priceInfo, className].join(' ')}>
      <div className={styles.top}>
        <img className={styles.thumb} src={thumb} />
        <div className={styles.topText}>
          <Typography variant="recrql-title-medium">{title}</Typography>
          {!!color && !!size && (
            <Typography variant="recrql-label-medium">
              {t('item.colorAndSizeX', { color, size })}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.info}>
        <div className={styles.row}>
          <Typography variant="recrql-label-medium">
            {t('checkout.subtotal')}
          </Typography>
          <Typography variant="recrql-label-medium">
            {t('item.price', { val: subtotal })}
          </Typography>
        </div>
        {deliveryMethod !== 'in_store' && (
          <div className={styles.row}>
            <Typography variant="recrql-label-medium">
              {t('checkout.shipping')}
            </Typography>
            <Typography variant="recrql-label-medium">
              {t('item.price', { val: deliveryPrice })}
            </Typography>
          </div>
        )}
        <div className={styles.row}>
          <Typography variant="recrql-label-medium">
            {t('checkout.vat')}
          </Typography>
          <Typography variant="recrql-label-medium">
            {t('item.price', { val: vat })}
          </Typography>
        </div>
        <div className={styles.bottomLine} />
        <div className={styles.row} style={{ fontWeight: 'bold' }}>
          <Typography variant="recrql-label-large">
            {t('checkout.total')}
          </Typography>
          <Typography variant="recrql-label-large">
            {t('item.price', { val: totalPrice })}
          </Typography>
        </div>
      </div>
    </div>
  )
}
