import React from 'react'
import Typography from '../../components/Typography'
import CopyContainer from '../CopyContainer'
import { useTranslation } from 'react-i18next'

export default function SupportView() {
  const { t } = useTranslation()
  return (
    <CopyContainer title="Support" subtitles={[]}>
      <Typography variant="general-body1">
        <div
          dangerouslySetInnerHTML={{
            __html: t('support', { interpolation: { escapeValue: false } }),
          }}
        />
      </Typography>
    </CopyContainer>
  )
}
