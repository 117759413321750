import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Item } from '../../../api/items/types'
import { AutoExpander } from '../../../components/Expander'
import Typography from '../../../components/Typography'
import { useIsMobile } from '../../../utils/useWindowDimensions'
import DescriptiveTypography from './DescriptiveTypography'
import Divider from './Divider'
import useItem from './useItem'
import _ from 'lodash'

const sizes = [
  'boett_color',
  'boett_material',
  'boett_size',
  'color',
  'dial_color',
  'glass_type',
  'length',
  'lock_color',
  'material',
  'measurements',
  'thickness',
  'width',
  'wrist_color',
  'wrist_material',
  'wrist_width',
]
const clockworks = [
  'caliber',
  'clockwork',
  'date_format',
  'second_meter',
  'water_resistant',
]
const others = [
  'bezel_ring',
  'for_who',
  'lock_type',
  // 'product_group',
  'product_types',
  'styles',
  'type_of_jewelery',
  'warranty_info',
]

const StyledSpecificationContainer = styled.div``

const TechnicalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4rem;
`

function TechnicalDataKey({ item, tk }: { item: Item; tk: string }) {
  const value = item.raw_data?.[tk]
  const { t } = useTranslation()
  const isArray = _.isArray(value)
  if (!value || (isArray && value.length == 0)) return null
  return (
    <DescriptiveTypography
      variant="Body2"
      key={tk}
      style={{
        fontWeight: 'bold',
        ...(isArray ? { display: 'flex' } : {}),
      }}
    >
      {t(`item.technical_data.${tk}`)}:{' '}
      {isArray ? (
        <ul
          style={{
            display: 'inline-block',
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
            paddingInlineStart: '20px',
          }}
        >
          {value.map((v) => (
            <li key={v}>
              <Typography variant="Body2" style={{ fontWeight: 'normal' }}>
                {v}
              </Typography>
            </li>
          ))}
        </ul>
      ) : (
        <Typography variant="Body2" style={{ fontWeight: 'normal' }}>
          {value}
        </Typography>
      )}
    </DescriptiveTypography>
  )
}

export default function SpecificationContainer() {
  const { item } = useItem()
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  if (!item) return null
  if (isMobile) {
    return (
      <StyledSpecificationContainer className="specification-container">
        <Divider withoutMargin />
        <AutoExpander
          title={t('item.specification')}
          contents={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[...sizes, ...clockworks, ...others].map((k) => (
                <TechnicalDataKey key={k} item={item} tk={k} />
              ))}
            </div>
          }
        />
        <Divider withoutMargin />
      </StyledSpecificationContainer>
    )
  }
  return (
    <StyledSpecificationContainer className="specification-container">
      <Typography variant="general-h3" className="title">
        {t('item.specification')}
      </Typography>
      <Divider />
      <div style={{ display: 'flex' }}>
        <TechnicalDataContainer>
          <Typography variant="general-button1" className="subtitle">
            STORLEK, MATERIAL &amp; FÄRG
          </Typography>
          {sizes.map((k) => (
            <TechnicalDataKey key={k} item={item} tk={k} />
          ))}
        </TechnicalDataContainer>
        {item.raw_data?.product_group == 'Klockor' && (
          <TechnicalDataContainer>
            <Typography variant="general-button1" className="subtitle">
              URVERK &amp; FUNKTIONER
            </Typography>
            {clockworks.map((k) => (
              <TechnicalDataKey key={k} item={item} tk={k} />
            ))}
          </TechnicalDataContainer>
        )}
        <TechnicalDataContainer>
          <Typography variant="general-button1" className="subtitle">
            ÖVRIGT
          </Typography>
          {others.map((k) => (
            <TechnicalDataKey key={k} item={item} tk={k} />
          ))}
        </TechnicalDataContainer>
      </div>
    </StyledSpecificationContainer>
  )
}
