import { usePagination, PaginationReturn } from '../common/hooks'
import { PaginationParams, Pagination } from '../common/types'
import { CreatePaymentMethodBody, PaymentMethod } from './types'
import {
  getPaymentMethods as getPaymentMethodsEP,
  createPaymentMethod as createPaymentMethodEP,
} from './endpoints'
import { useMutation } from 'react-query'

export function getPaymentMethods(
  params: PaginationParams = { limit: 30, offset: 0 }
): PaginationReturn<PaymentMethod, void, Pagination<PaymentMethod, void>> {
  return usePagination<PaymentMethod>(
    ['payment-method', params],
    params,
    getPaymentMethodsEP,
    true
  )
}

export function createPaymentMethod(
  payment_method_id: CreatePaymentMethodBody['payment_method_id']
) {
  return useMutation(
    ['create-payment-method', payment_method_id],
    (body: CreatePaymentMethodBody) => createPaymentMethodEP(body)
  )
}
