import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import styled from '@emotion/styled'
import Typography from '../components/Typography'
import { useIsMobile } from '../utils/useWindowDimensions'
import { colors } from '../theme'
import Anchor from '../components/Anchor'

const StyledDiv = styled.div``

const useStyles = createUseStyles<string, { isMobile: boolean }>({
  copyContainer: {
    padding: ({ isMobile }) =>
      isMobile ? '24px 71px' : '111px 180px 121px 180px',
  },
  title: {
    marginBottom: ({ isMobile }) => (isMobile ? '62px' : '51px'),
    display: 'block',
  },
  desktop: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

type Props = {
  title: string
  subtitles: string[]
  children: React.ReactNode
  contentStyle?: React.CSSProperties
}

export default function CopyContainer({
  title,
  subtitles,
  children,
  contentStyle,
}: Props) {
  const isMobile = useIsMobile()
  const styles = useStyles({ isMobile })
  useEffect(() => document.getElementById('layout')?.scrollTo({ top: 0 }), [])
  return (
    <StyledDiv className={styles.copyContainer}>
      <Typography className={styles.title} variant="general-h2">
        {title}
      </Typography>
      {isMobile && <TableOfContents subtitles={subtitles} />}
      <div className={!isMobile ? styles.desktop : ''}>
        <div style={contentStyle}>{children}</div>
        {!isMobile && <TableOfContents subtitles={subtitles} />}
      </div>
    </StyledDiv>
  )
}

const StyledSubtitle = styled(Typography)`
  margin-bottom: 32px;
  display: block;
  &:not(:first-child) {
    margin-top: 24px;
  }
`

export function Subtitle({ children }: { children: string }) {
  return (
    <>
      <Anchor id={children} />
      <StyledSubtitle variant="general-h3">{children}</StyledSubtitle>
    </>
  )
}

const StyledTableOfContents = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => `
  display: flex;
  flex-direction: column;
  width: 334px;
  align-self: flex-start;
  margin-left: ${isMobile ? 0 : '150px'};
  background-color: ${colors.backgroundSecondary};
  padding: 36px 24px;
  .toc-link {
    margin-top: 12px;
  }
  a {
    display: block;
    margin-bottom: 8px;
  }
`
)

export function TableOfContents({
  subtitles,
}: {
  subtitles: (string | undefined)[]
}) {
  const isMobile = useIsMobile()
  const vsubs = subtitles.filter((s) => !!s)
  if (vsubs.length == 0) return null
  return (
    <StyledTableOfContents isMobile={isMobile}>
      <Typography className="toc-title" variant="general-h3">
        Contents
      </Typography>
      {vsubs.map((s) => (
        <Typography className="toc-link" key={s} variant="general-link">
          <a href={`#${s}`}>{s}</a>
        </Typography>
      ))}
    </StyledTableOfContents>
  )
}
