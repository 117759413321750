import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { styles as themeStyles } from '../../../theme'
import Typography from '../../../components/Typography'
import NavLink from './NavLink'

type Props = {
  title: string
  middleRoutes?: Route[]
}
type Route = {
  title: string
  path: string
}

const StyledNavLink = styled(NavLink)`
  ${themeStyles.breadcrumbNavLink}
`

const StyledTypography = styled(Typography)`
  ${themeStyles.breadcrumbNavLink}
`

const Container = styled.div.attrs({
  className: 'breadcrumb',
})``

export default function Breadcrumb({ title, middleRoutes }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container>
      <StyledNavLink onClick={() => navigate('/')} variant="nav-button2">
        {t('nav.home')} /{' '}
      </StyledNavLink>{' '}
      {/* Root */}
      {middleRoutes?.map(({ title, path }) => (
        <StyledNavLink
          key={title + path}
          onClick={() => navigate(path)}
          variant="nav-button2"
        >
          {t(title)} /{' '}
        </StyledNavLink>
      ))}
      <StyledTypography variant="nav-subtitle1">{title}</StyledTypography>
    </Container>
  )
}
