import React from 'react'
import { colors } from '../theme'
import { createUseStyles } from 'react-jss'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import Typography from './Typography'

const useStyles = createUseStyles({
  container: {
    flexDirection: 'row',
    display: 'flex',
  },
  checkbox: {
    marginRight: '9px',
  },
})

export interface Props {
  isChecked: boolean
  onToggle?: () => void
  label?: string
  labelComponent?: React.ReactNode
  style?: React.CSSProperties
}
const Checkbox = ({
  isChecked,
  onToggle,
  label,
  labelComponent,
  style,
}: Props) => {
  const styles = useStyles()
  function handleToggle() {
    onToggle?.()
  }
  return (
    <div style={style} className={['checkbox', styles.container].join(' ')}>
      <div onClick={handleToggle} className={styles.checkbox}>
        {isChecked ? (
          <CheckBox sx={{ fontSize: 16 }} htmlColor={colors.textPrimary} />
        ) : (
          <CheckBoxOutlineBlank
            sx={{ fontSize: 16 }}
            htmlColor={colors.strokePrimary}
          />
        )}
      </div>
      {labelComponent ? (
        labelComponent
      ) : (
        <Typography variant="Body2">{label}</Typography>
      )}
    </div>
  )
}

export default Checkbox
